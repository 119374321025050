import React, { useState } from "react";
import GlobalUserForm from "../Global/GlobalUserForm";
import useAppUtils, {
  useGroupList,
  useUnitList,
  useUserList,
} from "../../utils/useAppUtils";
import { ErrorMsg, Textlabel } from "./../Global/GlobalText";
import { Table2 } from "./../Global/Table2";
import GlobalSearch from "./../Global/GlobalSearch";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { GrEdit } from "react-icons/gr";
import userImage from "../../Assets/ProfileIcon.png";
import { BorderInput, BorderSelect } from "./../Global/GlobalPlaceholder";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { createWarehouseAction } from "../../redux/users/action";
import { toast } from "react-toastify";
import { BlueBackgroundButton } from "../Global/GlobalButtons";

const validationSchema = Yup.object({
  username: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  userid: Yup.string().required("User is required"),
  groupid: Yup.string().required("Group is required"),
  unitid: Yup.string().required("Unit is required"),
});
export const CreateSubWarehouse = () => {
  const users = useUserList();
  const units = useUnitList();
  const groups = useGroupList();
  const { dispatch, navigate } = useAppUtils();

  const initialValues = {
    username: "",
    description: "",
    userid: "",
    groupid: "",
    unitid: "",
  };

  const [showReconciliation, setShowReconciliation] = useState(false);
  const handleClose = () => {
    setShowReconciliation(false);
  };
  const handleShowReconciliation = () => setShowReconciliation(true);
  const handleCreateWarehouse = (values, { setSubmitting, resetForm }) => {
    dispatch(
      createWarehouseAction(values, (response) => {
        setSubmitting(false);
        if (response?.status === 200) {
          resetForm();
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
  return (
    <StyleProfile>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleCreateWarehouse}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="formtext">
              <div className="profile_edit">
                <img src={userImage} alt="profile edit" />
                <button className="edit_icon">
                  <GrEdit />
                </button>
              </div>
              <div className="input_select">
                <div className="input_select_name">
                  <Textlabel htmlFor="name">Warehouse Name</Textlabel>

                  <Field
                    name="username"
                    as={BorderInput}
                    placeholder="XYZwareHouse"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    as={ErrorMsg}
                    className="error"
                  />
                </div>
                <div className="input_select_desc">
                  <Textlabel htmlFor="description">Description about warehouse</Textlabel>
                  <Field
                    name="description"
                    as={BorderInput}
                    placeholder="Writedescriptionhere"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    as={ErrorMsg}
                    className="error"
                  />
                </div>
                <div className="input_select_unit">
                  <Textlabel htmlFor="Member from Unit Name">Member from Unit Name</Textlabel>
                  <Field name="unitid" as={BorderSelect}>
                    <option value="">Select Unit</option>
                    {units?.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unit_name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="unitid"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="input_select">
                <div
                  className="input_select_assign"
                  onClick={handleShowReconciliation}
                >
                  <Textlabel htmlFor="Assign To">Assign To</Textlabel>

                  <Field name="groupid" as={BorderSelect}>
                    <option value="">Select Group</option>
                    {groups?.map((group) => {
                      console.log("Rendering Group:", group); 
                      return (
                        <option key={group.id} value={group.id}>
                          {group.group_name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="groupid"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="input_select_group">
                  <Textlabel htmlFor="group name">Member From Group Name</Textlabel>
                  <Field name="userid" as={BorderSelect}>
                    <option value="">Select Member</option>
                    {users?.map((user) => {
                      console.log("Rendering Group:", user); // Add this to check if it's rendering
                      return (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="groupid"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="input_select_sub_div">
                 <Textlabel htmlFor="role">Role</Textlabel>

                  <BorderSelect>
                    <option>Type Role here </option>
                  </BorderSelect>
                </div>
              </div>
            </div>
            <div>
              <BlueBackgroundButton type="submit" disabled={isSubmitting}>
                Save
              </BlueBackgroundButton>
            </div>
          </Form>
        )}
      </Formik>

      <Modal show={showReconciliation} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <div>
            <GlobalSearch search="Search by Name,Id,Group,Unit" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table2 request="Request Now" />
        </Modal.Body>
      </Modal>
      <div>
        <GlobalUserForm />
      </div>
    </StyleProfile>
  );
};
const StyleProfile = styled.section`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 40px;
  flex-wrap: wrap;
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    .formtext {
      display: flex;
      justify-content: space-evenly;
    }
  }
  .profile_edit {
    display: flex;
    position: relative;
    align-items: baseline;
    img {
      width: 120px;
      height: 120px;
    }
    .edit_icon {
      background-color: transparent;
      border: none;
    }
    svg {
      cursor: pointer;
      background-color: #1814f3;
      color: #fff;
      width: 35px;
      height: 35px;
      padding: 7px;
      position: absolute;
      top: 30%;
      left: 72px;
      border-radius: 50%;
    }
  }
  .input_select {
    display: flex;
    flex-direction: column;
    .input_select_sub_div,
    .input_select_name,
    .input_select_desc,
    .input_select_unit,
    .input_select_assign,
    .input_select_group {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      label {
        padding: 0px 10px 10px;
        font-size: 14px;
      }
      input,
      select {
        border: 1.5px solid #dfeaf2;
        background-color: #fff;
      }
      input::placeholder,
      select::placeholder {
        font-size: 13px !important;
        font-weight: 400;
        line-height: 16px;
        color: #4b4949;
      }
    }
  }
`;
