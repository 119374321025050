import React, { useState } from "react";
import {
  CheckboxInput,
  CheckboxLabel,
} from "./components/Global/GlobalPlaceholder";
import styled from "styled-components";

export const Test = () => {
  const [showListTwo, setShowListTwo] = useState(false);

  const handleToggleTwo = () => {
    setShowListTwo(!showListTwo);
  };

  return (
    <Root>
      <div>
        <CheckboxInput
          type="checkbox"
          id="child5"
          onChange={handleToggleTwo}
          checked={showListTwo}
        />
        <CheckboxLabel>What type of equipment</CheckboxLabel>
      </div>
      {showListTwo && (
        <div className="list_show">
          <div>
            <CheckboxInput type="checkbox" />
            <CheckboxLabel>Lorem ipsum dolor sit</CheckboxLabel>
          </div>
          <div>
            <CheckboxInput type="checkbox" />
            <CheckboxLabel>Lorem ipsum dolor sit</CheckboxLabel>
          </div>
          <div>
            <CheckboxInput type="checkbox" />
            <CheckboxLabel>Lorem ipsum dolor sit</CheckboxLabel>
          </div>
        </div>
      )}
    </Root>
  );
};
const Root = styled.section`
.list_show{
  display: flex;
  flex-direction: column;
}

`;
