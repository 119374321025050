// import React, { useState } from "react";
// import { BlueBackgroundButton } from "../Global/GlobalButtons";
// import styled from "styled-components";
// import { GlobalSearchMini } from "../Global/GlobalSearch";
// import { DottedSelect } from "../Global/GlobalPlaceholder";
// import GlobalTable from "../Global/GlobalTable";
// import { CiImageOn } from "react-icons/ci";
// import { useCategoryList } from "../../utils/useAppUtils";

// export const Category = () => {
  
//   const [data, setData] = useState([
      
//   ]);

//   const categories = useCategoryList(); 

//   const columns = [
//     { header: "Select", accessor: "select" },
//     { header: "", accessor: "imageicon" },
//     { header: "Title", accessor: "title" },
//     { header: "Products", accessor: "products" },
//     { header: "Product Conditions", accessor: "productQuantity" },
//   ];

//   const handleSelectChange = (rowIndex) => {
//     setData((prevData) => {
//       const newData = [...prevData];
//       newData[rowIndex].selected = !newData[rowIndex].selected;
//       return newData;
//     });
//   };

//   return (
//     <Root className="home">
//       <div style={{ textAlign: "end" }}>
//         <BlueBackgroundButton>Create Category</BlueBackgroundButton>
//       </div>
//       <div className="table_seprate">
//         <>
//           <GlobalSearchMini searching="Searching all collections" />
//         </>
//         <>
//           <DottedSelect>
//             <option>Sales channel</option>
//             <option>22</option>
//           </DottedSelect>
//           <DottedSelect>
//             <option>Type</option>
//             <option>22</option>
//           </DottedSelect>
//         </>
//         <>
//           <GlobalTable
//             columns={columns}
//             data={categories.map( (category, index) => ({
//               title: "fdsdf",
//               imageicon:<CiImageOn />,
//               products: "2023-08-28 12:34",
//               categoryName: "Electronics",
//               productQuantity: "asdasd"
              
//           }))}
//             onSelectChange={handleSelectChange}
//           />
//         </>
//       </div>
//     </Root>
//   );
// };
// const Root = styled.section`
// background-color: #fff;
// border-radius: 50px;
// padding: 20px;
//   .table_seprate {
//     margin: 20px 0px;
//     padding: 0px 10px 10px;
//     background-color: #fff;
//     border: 1px solid gray;
//     border-radius: 20px;
//     svg{
//       width: 26px;
//       height: 26px;
//     }
//   }
// `;

import React, { useState } from "react";
import { BlueBackgroundButton } from "../Global/GlobalButtons";
import styled from "styled-components";
import { GlobalSearchMini } from "../Global/GlobalSearch";
import { DottedSelect } from "../Global/GlobalPlaceholder";
import GlobalTable from "../Global/GlobalTable";
import { CiImageOn } from "react-icons/ci";
import { useCategoryList } from "../../utils/useAppUtils";

export const Category = () => {
  
  const [data, setData] = useState([
      
  ]);

  const categories = useCategoryList(); 

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "", accessor: "imageicon" },
    { header: "Title", accessor: "title" },
    { header: "Products", accessor: "products" },
    { header: "Product Conditions", accessor: "productQuantity" },
  ];

  const handleSelectChange = (rowIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].selected = !newData[rowIndex].selected;
      return newData;
    });
  };


  const formattedData = categories?.map((category, index) => ({
    select: (
      <input
        type="checkbox"
        checked={data[index]?.selected || false}
        onChange={() => handleSelectChange(index)}
      />
    ),
    imageicon: category.image ? (
      <img
        src={`https://your-image-domain.com/${category.image}`}
        alt={category.name}
        style={{ width: "40px", height: "40px", borderRadius: "8px" }}
      />
    ) : (
      <CiImageOn />
    ),
    title: category.name,
    products: category.sku,
    productQuantity: category.description,
  }));
  const handleRowClick = (row) => {
    console.log("Row clicked:", row);
  };
  return (
    <Root className="home">
      <div style={{ textAlign: "end" }}>
        <BlueBackgroundButton>Create Category</BlueBackgroundButton>
      </div>
      <div className="table_seprate">
        <GlobalSearchMini searching="Searching all collections" />
        <DottedSelect>
          <option>Sales channel</option>
          <option>22</option>
        </DottedSelect>
        <DottedSelect>
          <option>Type</option>
          <option>22</option>
        </DottedSelect>
        <GlobalTable columns={columns} data={formattedData}  onRowClick={handleRowClick} />
      </div>
    </Root>
  );
};
const Root = styled.section`
background-color: #fff;
border-radius: 50px;
padding: 20px;
  .table_seprate {
    margin: 20px 0px;
    padding: 0px 10px 10px;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 20px;
    svg{
      width: 26px;
      height: 26px;
    }
  }
`;
