import {
  addUser,
  adminLogin,
  adminRegister,
  categoryList,
  createCategory,
  createGroup,
  createProduct,
  createUnit,
  createWarehouse,
  groupList,
  HIDE_LOADER,
  productPut,
  productView,
  SHOW_LOADER,
  unitList,
  userList,
  warehouseList,
  productList,
  LOGOUT_USER,
  warehouseLogin,
  userPermissionView,
  userLogin,
  userProductFetch,
  userSelectedList,
  assignProduct,
  roleView,
  assignProductById,
  requestEquipmentFetch,
  reconcileProduct,
} from "../type";
import { SET_SELECTED_PRODUCT_ID, SET_SELECTED_UNIT_ID, SET_SELECTED_USER_ID } from "./action";

const initialState = {
  user: {},
  userCheck: false,
  role: "",
  appDetails: {},
  isLoading: false,
  products: [], // Separate state for product lists
  productDetails: {}, // Separate state for a specific product view
  categories: [], // State for categories
  warehouses: [], // State for warehouses
  groups: [], // State for groups
  units: [], // State for units
  users: [],
  productView:[],
  userProductFetch:[],
  isLoggedIn: false,
  selectedProductId: null,
  userproducts:[],
  usersSelect:[],
  selectedUnitId: null,
  assign:[],
  selectedUserId:null,
  roleView:[],
  productsById:null,
  reqEqu:[],
  reconcile:[]
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT_ID:
      return {
        ...state,
        selectedProductId: action.payload,
      };
    case LOGOUT_USER:
      return { ...state, userDetails: {}, isLoggedIn: false };
    case "USER_DATA":
      return { ...state, user: action.payload };
    case "LOADING_DATA":
      return { ...state, isLoading: action.payload };
    case "USER_CHECK":
      return { ...state, userCheck: action.payload };
    case "APP_DETAILS":
      return { ...state, appDetails: action.payload };
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };
      case SET_SELECTED_USER_ID:
        return {
          ...state,
          selectedUserId: action.payload,
        };
    case adminLogin.ADMIN_LOGIN:
      return { ...state, user: action.data };
    case userLogin.USER_LOGIN:
      return { ...state, user: action.data };
      case warehouseLogin.WAREHOUSE_LOGIN:
        return { ...state, user: action.data };
    case adminRegister.ADMIN_REGISTER:
      return { ...state, user: action.data };
    case addUser.ADD_USER:
      return { ...state, user: action.data };
    case reconcileProduct.RECONCILE_PRODUCT:
      return { ...state, reconcile: action.data };

    case createGroup.CREATE_GROUP:
      return { ...state, groups: [...state.groups, action.data] };

    case createUnit.CREATE_Unit:
      return { ...state, units: [...state.units, action.data] };
    case assignProduct.ASSIGN_PRODUCT:
      return { ...state, assign: action.data };
    case requestEquipmentFetch.REQUEST_EQUIPMENT_FETCH:
      return { ...state, reqEqu: action.data };
    case createWarehouse.CREATE_WAREHOUSE:
      return { ...state, warehouses: [...state.warehouses, action.data] };

    case createCategory.CREATE_CATEGORY:
      return { ...state, categories: [...state.categories, action.data] };
    case createProduct.CREATE_PRODUCT:
      return { ...state, products: [...state.products, action.data] };

    case unitList.UNIT_LIST:
      return { ...state, units: action.data };

    case groupList.GROUP_LIST:
      return { ...state, groups: action.data };

    case userList.USER_LIST:
      return { ...state, users: action.data };
    case userSelectedList.USER_SELECTED_LIST:
      return { ...state, usersSelect: action.data };
    case categoryList.CATEGORY_LIST:
      return { ...state, categories: action.data };

    case warehouseList.WAREHOUSE_LIST:
      return { ...state, warehouses: action.data };
    case roleView.ROLE_VIEW:
      return { ...state, roleView: action.data };

    case productPut.PRODUCT_PUT:
      return { ...state, productDetails: action.data }; // Update product details after put
      
    case productView.PRODUCT_VIEW:
      return { ...state, productDetails: action.data };  
    case userProductFetch.USER_PRODUCT_FETCH:
      return { ...state, userProductFetch: action.data };  
    case userPermissionView.USER_PERMISSION_VIEW:
      return { ...state, userPermissionView: action.data };  

    case productList.PRODUCT_LIST:
      return { ...state, products: action.data };
    case assignProductById.ASSIGN_PRODUCT_BY_ID:
      return { ...state, productsById: action.data };
      case SET_SELECTED_UNIT_ID:
        return {
          ...state,
          selectedUnitId: action.payload,
        };
    default:
      return state;
  }
};

export default userReducer;
