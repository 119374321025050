import React from "react";
import Layout from "./mainLayout/Layout";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Pages/Dashboard";
import { WarehouseManage } from "./components/warehouse/WarehouseManage";
import { AddProduct } from "./components/warehouse/AddProducts";
import { AddCategory } from "./components/warehouse/AddCategory";
import { Category } from "./components/warehouse/Category";
import { Product } from "./components/warehouse/Product";
import { SubWarehouse } from "./components/warehouse/SubWarehouse";
import { AllGroup } from "./components/group/AllGroup";
import { History } from "./components/group/History";
import { Request } from "./components/group/Request";
import AddUsers from "./components/users/AddUsers";
import RequestEquipment from "./components/Pages/RequestEquipment";
import { Hierarchy } from "./components/Pages/Hierarchy";
import UniteProfile from "./components/Pages/UniteProfile";
import { ManageRole } from "./components/manageRoles/ManageRole";
import { InvitedUsers } from "./components/manageRoles/InvitedUsers";
import UsersList from "./components/users/UsersList";
import InvitedList from "./components/users/InvitedList";
import "bootstrap/dist/css/bootstrap.min.css";
import { GroupCreate } from "./components/group/groupCreate/GroupCreate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./components/common/AdminLogin";
import RegistrationPage from "./components/common/RegistrationPage";
import { ViewProduct } from "./components/warehouse/ViewProducts";
import WarehouseLogin from "./components/common/WarehouseLogin";
import {  useSelector } from "react-redux";
import { Test } from "./Test";
import DummyFolder from "./components/users/DummyFolder";
import UserLogin from "./components/common/UserLogin";
import { Notifications } from "react-push-notification";
import Listofusers from "./components/group/Listofusers";
import UserEquipmentlist from "./components/group/UserEquipmentlist";

export default function App() {
  // const userDetails = useSelector((state) => state?.users.user);
  const userCheck = useSelector((state) => state?.users?.userCheck);
  const token = localStorage.getItem("token");

  return (
    <div>
      <Layout>
        <ToastContainer
          className="toast-container"
          toastClassName="toast-message"
        />
          <Notifications />
        <Routes>
          {userCheck && token ? (
            <>
              <Route path="/" element={<Dashboard />} />

              <Route path="/dummy" element={<DummyFolder />} />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/warehouse_manage" element={<WarehouseManage />} />
              <Route
                path="/warehouse_manage/add_product"
                element={<AddProduct />}
              />
              <Route
                path="/warehouse_manage/add_category"
                element={<AddCategory />}
              />
              <Route path="/warehouse_manage/category" element={<Category />} />
              <Route path="/warehouse_manage/product" element={<Product />} />
              <Route
                path="/warehouse_manage/viewproduct"
                element={<ViewProduct />}
              />
              <Route
                path="/warehouse_manage/viewproduct/:id"
                element={<ViewProduct />}
              />
              <Route
                path="/warehouse_manage/create_warehouse"
                element={<SubWarehouse />}
                
              />

              <Route path="/group_create" element={<GroupCreate />} />
              <Route path="/group_create/all_group" element={<AllGroup />} />
              <Route path="/group_create/request" element={<Request />} />
              <Route path="/group_create/history" element={<History />} />
              <Route path="/add_users" element={<AddUsers />} />
              <Route path="/add_users/users_list" element={<UsersList />} />
              <Route path="/add_users/invited_list" element={<InvitedList />} />
              <Route path="/manage_role" element={<ManageRole />} />
              <Route
                path="/manage_role/invited_users"
                element={<InvitedUsers />}
              />

              <Route path="/unite_profile" element={<UniteProfile />} />
              <Route path="/request_equipment" element={<RequestEquipment />} />
              <Route path="/hierarchy" element={<Hierarchy />} />
              <Route path="/test" element={<Test />} />
              <Route path="/unit_userlist" element={<Listofusers />} />
              <Route path="/unit_userlist/:id" element={<Listofusers />} />
              <Route path="/userlist-equipment/:id" element={<UserEquipmentlist />} />

            </>
          ) : (
            <>
              {" "}
              <Route path="/adminlogin" element={<AdminLogin />} />
              <Route path="/userlogin" element={<UserLogin />} />
              <Route path="/" element={<UserLogin/>} />
              <Route path="/login" element={<WarehouseLogin />} />
              <Route path="/register" element={<RegistrationPage />} />{" "}
            </>
          )}
          <>
            {" "}
            <Route path="/adminlogin" element={<AdminLogin />} />
            <Route path="/" element={<UserLogin/>} />
            <Route path="/userlogin" element={<UserLogin />} />
            <Route path="/login" element={<WarehouseLogin />} />
            <Route path="/register" element={<RegistrationPage />} />{" "}
          </>
        </Routes>
      </Layout>
    </div>
  );
}
