import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GlobalSearch from "../Global/GlobalSearch";
import { FormTextBlack, Textlabel } from "../Global/GlobalText";
import {
  BorderInput,
  BorderSelect,
  CheckboxInput,
  CheckboxLabel,
} from "../Global/GlobalPlaceholder";
import { GrEdit } from "react-icons/gr";
import userImage from "../../Assets/ProfileIcon.png";
import { Input } from "antd";
import useAppUtils, {
  useGroupList,
  useProductList,
  useRoleList,
  useUnitList,
  useUserList,
} from "../../utils/useAppUtils";
import { addUserAction } from "../../redux/users/action";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BlueBackgroundButton } from "../Global/GlobalButtons";
import { useSelector } from "react-redux";
import {
  addPermission,
  addSelection,
  removePermission,
  removeSelection,
} from "./DummyFolder/action";
import axios from "axios";
import { API_BASE_URL } from "../../utils/https";

const validationSchema = Yup.object({
  role: Yup.string().required("Role is required"),
  name: Yup.string().required("Name is required"),
  username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  mobile_number: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  groupid: Yup.string().required("Group is required"),
  unitid: Yup.string().required("Unit is required"),
  permission_ids: Yup.array()
    .min(1, "At least one permission is required.")
    .required("Permission is required"),
  product_ids: Yup.array()
    .min(1, "At least one product is required.")
    .required("Product ID is required."),
  assign_to: Yup.array().required("Allow multiple is required"),
  group_id: Yup.array()
    .min(1, "At least one group is required.")
    .required("Group ID is required."),
  unit_id: Yup.array()
    .min(1, "At least one unit is required.")
    .required("Unit ID is required."),
});

const permissionMap = {
  child1: {
    id: 2,
    name: "Transfer equipment (without the need for third-party approval)",
  },
  child2: { id: 3, name: "Choice of Place (Group or Unit)" },
  child3: { id: 7, name: "Select equipment type" },
  child5: { id: 9, name: "What type of equipment" },
  child25: { id: 11, name: "Change equipment name" },
  child26: { id: 12, name: "Change category to equipment" },
  child27: { id: 13, name: "Add equipment to the warehouse" },
  child38: { id: 14, name: "Creating new groups in the unit" },
  child41: { id: 16, name: "Move groups within the unit" },
  thirdPartyParentCheckbox: {
    id: 15,
    name: "Transfer equipment (with the need for third-party approval)",
  },
};
export const AddUserTab = () => {
  const { dispatch, navigate } = useAppUtils();
  const units = useUnitList();
  const groups = useGroupList();
  const users = useUserList();
  const products = useProductList();
  const permissions = useSelector((state) => state.selection.permissions || {});
  const role = useRoleList();
  console.log("role list", role);
  const [checkedItems, setCheckedItems] = useState({
    selectAll: false,
    child1: false,
    child2: false,
    child2_1: false,
    child2_2: false,
    child3: false,
    child4: false,
    child5: false,
    thirdPartyParentCheckbox: false,
    child6: false,
    child7: false,
    child8: false,
    child9: false,
    child10: false,
    child11: false,
    child12: false,
    child13: false,
    child14: false,
    noLimitCheckbox: false,
    requestEquipmentParentCheckbox: false,
    child15: false,
    child16: false,
    child17: false,
    child18: false,
    child19: false,
    child25: false,
    child26: false,
    child27: false,
    child37: false,
    child38: false,
    child40: false,
    child41: false,
  });

  const initialValues = {
    role: "",
    name: "",
    username: "",
    email: "",
    password: "",
    mobile_number: "",
    groupid: "",
    unitid: "",
    permission_ids: [],
    product_ids: [],
    assign_to: [],
    group_id: [],
    unit_id: [],
  };

  const handleAddUser = async (values, formik) => {
    const { setSubmitting, setFieldValue } = formik;
    try {
      const axiosConfig = {
        // withCredentials: true,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const response = await axios.post(
        `${API_BASE_URL}/user-create`,
        values,
        axiosConfig
      );
      console.log("resres", response?.data?.data);
      setSubmitting(false);
      if (response?.status === 200) {
        formik.resetForm();
        selectedItems.forEach((item) => dispatch(removeSelection(item)));
        toast.success(response.message);
        navigate("/add_users/users_list")
      } else {
        formik.resetForm();
        selectedItems.forEach((item) => dispatch(removeSelection(item)));
        toast.error("Process failed. Please try again.");
      }
    } catch (err) {
      console.log("err", err);
      toast.error("An error occurred during login");
    }
  };

  // ----------------------------------------PERMISSION JS LOGICS--------------------------------------------------?

  const selectedItems = useSelector(
    (state) => state.selection.selectedItems || []
  );

  const handleCheckboxChange = (e, formik) => {
    const { id, checked } = e.target;
    const permission = permissionMap[id];

    if (permission) {
      // Update the formik state
      const currentPermissionIds = new Set(formik.values.permission_ids);
      formik.setFieldValue(
        "permission_ids",
        checked
          ? [...formik.values.permission_ids, permission.id] // Add ID if checked
          : formik.values.permission_ids.filter(
              (permId) => permId !== permission.id
            ) // Remove ID if unchecked
      );

      // Add or remove permission from Redux
      if (checked) {
        currentPermissionIds.add(permission.id);
        dispatch(addPermission(permission));
        console.log(`Added Permission:`, permission);
      } else {
        dispatch(removePermission(permission.id));
        console.log(`Removed Permission:`, permission.id);
      }
    }

    setCheckedItems((prev) => {
      let newState = { ...prev, [id]: checked };

      if (id === "child1" && checked) {
        ["child2_1", "child2_2"].forEach((childId) => {
          const childPermission = permissionMap[childId];
          if (!childPermission) {
            console.warn(`Permission not found for childId: ${childId}`);
            return; // Skip if permission doesn't exist
          }

          if (childPermission) {
            newState[childId] = true;
            dispatch(addPermission(childPermission));
            formik.setFieldValue("permission_ids", [
              ...(formik.values.permission_ids || []),
              childPermission.id,
            ]);
          }
        });
      }

      if (id === "selectAll") {
        Object.keys(prev).forEach((key) => {
          newState[key] = checked;
        });
      }
      if (id === "child1" && checked) {
        newState = {
          ...newState,
          thirdPartyParentCheckbox: false,
          child6: false,
          child7: false,
          child8: false,
          child9: false,
          child10: false,
          child12: false,
          child13: false,
          child14: false,
        };
      } else if (id === "thirdPartyParentCheckbox" && checked) {
        newState = {
          ...newState,
          child1: false,
          child2: false,
          child2_1: false,
          child2_2: false,
          child3: false,
          child4: false,
          child5: false,
        };
      }

      return newState;
    });
  };

  const handleItemClick = (itemType, itemId, formik) => {
    if (!selectedItems) return;

    const isSelected = selectedItems.some(
      (item) => item.type === itemType && item.id === itemId
    );
    const payload = { type: itemType, id: itemId };

    if (isSelected) {
      dispatch(removeSelection(payload));
      if (itemType === "products") {
        formik.setFieldValue(
          "product_ids",
          formik.values.product_ids.filter((id) => id !== itemId)
        );
      } else if (itemType === "groups") {
        formik.setFieldValue(
          "group_id",
          formik.values.group_id.filter((id) => id !== itemId)
        );
      } else if (itemType === "units") {
        formik.setFieldValue(
          "unit_id",
          formik.values.unit_id.filter((id) => id !== itemId)
        );
      }
    } else {
      dispatch(addSelection(payload));
      if (itemType === "products") {
        formik.setFieldValue("product_ids", [
          ...formik.values.product_ids,
          itemId,
        ]);
      } else if (itemType === "groups") {
        formik.setFieldValue("group_id", [...formik.values.group_id, itemId]);
      } else if (itemType === "units") {
        formik.setFieldValue("unit_id", [...formik.values.unit_id, itemId]);
      }
    }
  };

  useEffect(() => {
    const logSelectedNames = () => {
      const selectedGroups = selectedItems
        .filter((item) => item.type === "groups")
        .map(
          (item) => groups.find((group) => group.id === item.id)?.group_name
        );

      const selectedUnits = selectedItems
        .filter((item) => item.type === "units")
        .map(
          (item) => units.find((unit) => unit.unit_id === item.id)?.unit_name
        );

      const selectedProducts = selectedItems
        .filter((item) => item.type === "products")
        .map(
          (item) =>
            products.find((product) => product.product_id === item.id)?.name
        );
      console.log("Selected Groups:", selectedGroups);
      console.log("Selected Units:", selectedUnits);
      console.log("Selected Products:", selectedProducts);
    };
    logSelectedNames();
  }, [selectedItems, groups, units, products]);

  return (
    <Root>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => handleAddUser(values, formik)}
      >
        {(formik) => (
          <Form>
            <div className="profile_input">
              <div className="profile_edit">
                <img src={userImage} alt="profile edit" />
                <button className="edit_icon">
                  <GrEdit />
                </button>
              </div>

              <div className="input_select">
                <div className="input_select_name">
                  <Textlabel htmlFor="name">Your Name</Textlabel>
                  <Field name="name" as={BorderInput} placeholder="XYZ" />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>

                <div className="input_select_group">
                  <Textlabel htmlFor="email">E-mail</Textlabel>
                  <Field
                    name="email"
                    as={BorderInput}
                    placeholder="logstix@gmail.com"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="input_select_desc">
                  <Textlabel htmlFor="group">Assign Group</Textlabel>
                  <Field name="groupid" as={BorderSelect}>
                    <option value="">Select Group</option>
                    {groups?.map((group) => {
                      return (
                        <option key={group.id} value={group.id}>
                          {group.group_name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="groupid"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="input_select_unit">
                  <Textlabel htmlFor="unit">Unit</Textlabel>
                  <Field name="unitid" as={BorderSelect}>
                    <option value="">Select Unit</option>
                    {units?.map((unit) => (
                      <option key={unit.unit_id} value={unit.unit_id}>
                        {unit.unit_name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="unitid"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="input_select">
                <div className="input_select_assign">
                  <Textlabel htmlFor="user name">User Name</Textlabel>
                  <Field name="username" as={BorderInput} placeholder="xyz" />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="input_select_sub_div">
                  <Textlabel htmlFor="password">Password</Textlabel>
                  <Field
                    name="password"
                    as={Input.Password}
                    placeholder="Enter your password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="input_select_group">
                  <Textlabel htmlFor="number">Mobile Number</Textlabel>
                  <Field
                    name="mobile_number"
                    as={BorderInput}
                    placeholder="Mobile Number"
                  />
                  <ErrorMessage
                    name="mobile_number"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="input_select_sub_div">
                  <Textlabel htmlFor="role">Role</Textlabel>
                  <Field name="role" as={BorderSelect}>
                    <option value="">Select Role</option>
                    {role?.map((role) => (
                      <option key={role.role} value={role.role}>
                        {role.role}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="role" component="div" className="error" />
                </div>
              </div>
            </div>

            <div className="search">
              <FormTextBlack>Select Permission</FormTextBlack>
              <GlobalSearch search="Search for something" />
            </div>
            {/* ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,PERMISSION TABLE,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */}
            <>
              <StyleUserForm>
                <div className="main_heading">
                  <div className="check_label">
                    <CheckboxInput
                      type="checkbox"
                      id="selectAll"
                      checked={checkedItems.selectAll}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel htmlFor="selectAll">
                      Select All Permission
                    </CheckboxLabel>
                  </div>
                </div>

                <div className="first_head_div left_marg">
                  <div className="check_label">
                    <CheckboxInput
                      type="checkbox"
                      id="child1"
                      checked={checkedItems.child1}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel
                      htmlFor="child1"
                      value="Transfer equipment (without the need for third-party
                      approval)"
                      id="2"
                    >
                      Transfer equipment (without the need for third-party
                      approval)
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child2"
                      checked={checkedItems.child2 || checkedItems.child1}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel
                      htmlFor="child2"
                      value="Choice of Place (Group or Unit)"
                      id="3"
                    >
                      Choice of Place (Group or Unit)
                    </CheckboxLabel>
                  </div>

                  <div className="dual_checkbox d-flex">
                    <div className="check_label left_spac">
                      <CheckboxInput
                        type="checkbox"
                        id="child2_1"
                        checked={checkedItems.child2_1 || checkedItems.child1}
                        onChange={(e) => handleCheckboxChange(e, formik)}
                      />
                      <CheckboxLabel htmlFor="child2_1">Group</CheckboxLabel>
                    </div>

                    <div className="check_label left_spac">
                      <CheckboxInput
                        type="checkbox"
                        id="child2_2"
                        checked={checkedItems.child2_2 || checkedItems.child1}
                        onChange={(e) => handleCheckboxChange(e, formik)}
                      />
                      <CheckboxLabel htmlFor="child2_2">Unit</CheckboxLabel>
                    </div>
                  </div>
                  <div className="dual_checkbox d-flex ">
                    <div className="check_label  ">
                      {(checkedItems.child2_1 || checkedItems.child1) && (
                        <div className="check_label left_spac">
                          <table className="group-table" value="group_id">
                            <thead>
                              <tr>
                                <th>Group Name</th>
                              </tr>
                            </thead>
                            {groups?.map((group) => (
                              <tbody key={group.id}>
                                <tr>
                                  <td
                                    onClick={() =>
                                      handleItemClick(
                                        "groups",
                                        group.id,
                                        formik
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: selectedItems.some(
                                        (item) =>
                                          item.type === "groups" &&
                                          item.id === group.id
                                      )
                                        ? "bold"
                                        : "normal",
                                    }}
                                  >
                                    <li>{group.group_name}</li>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      )}
                    </div>
                    <div className="check_label  ">
                      {(checkedItems.child2_2 || checkedItems.child1) && (
                        <div className="check_label left_spac">
                          <table className="group-table" value="unit_id">
                            <thead>
                              <tr>
                                <th>Unit Name</th>
                              </tr>
                            </thead>
                            {units?.map((unit) => (
                              <tbody key={unit.unit_id}>
                                <tr>
                                  <td
                                    onClick={() =>
                                      handleItemClick(
                                        "units",
                                        unit.unit_id,
                                        formik
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: selectedItems.some(
                                        (item) =>
                                          item.type === "units" &&
                                          item.id === unit.unit_id
                                      )
                                        ? "bold"
                                        : "normal",
                                    }}
                                  >
                                    <li>{unit.unit_name}</li>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child3"
                      checked={checkedItems.child3 || checkedItems.child1}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel
                      htmlFor="child3"
                      id="7"
                      value="Select equipment type"
                    >
                      Selection of equipment type:
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_hig_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child4"
                      checked={
                        checkedItems.child4 ||
                        checkedItems.child1 ||
                        checkedItems.child3
                      }
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel
                      htmlFor="child4"
                      style={{ color: "#FF0000" }}
                    >
                      Choose between contains / does not contain
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_hig_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child5"
                      checked={
                        checkedItems.child5 ||
                        checkedItems.child1 ||
                        checkedItems.child3
                      }
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel
                      htmlFor="child5"
                      id="9"
                      value="What type of equipment"
                    >
                      What type of equipment
                    </CheckboxLabel>
                  </div>
                </div>
                <div className="check_label  left_hig_spac ">
                  {(checkedItems.child3 ||
                    checkedItems.child5 ||
                    checkedItems.child1) && (
                    <div className="check_label left_spac">
                      <table className="group-table" value="product_ids">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                          </tr>
                        </thead>
                        {products?.map((product) => (
                          <tbody key={product.product_id}>
                            <tr>
                              <td
                                onClick={() =>
                                  handleItemClick(
                                    "products",
                                    product.product_id,
                                    formik
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  fontWeight: selectedItems.some(
                                    (item) =>
                                      item.type === "products" &&
                                      item.id === product.product_id
                                  )
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                <li>{product.name}</li>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                </div>
              </StyleUserForm>
              -------------------------------------------
              <StyleUserForm>
                <div className="first_head_div left_marg">
                  <div className="trans_equipment">
                    <div className="check_label">
                      <CheckboxInput
                        type="checkbox"
                        id="thirdPartyParentCheckbox"
                        checked={checkedItems.thirdPartyParentCheckbox || false}
                        onChange={(e) => handleCheckboxChange(e, formik)}
                      />
                      <CheckboxLabel
                        htmlFor="thirdPartyParentCheckbox"
                        value=" Transfer equipment (with the need for third-party
                        approval)"
                        id="15"
                      >
                        Transfer equipment (with the need for third-party
                        approval) 15
                      </CheckboxLabel>
                    </div>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child6"
                      checked={
                        checkedItems.child6 ||
                        checkedItems.thirdPartyParentCheckbox ||
                        false
                      }
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="child6">
                      Who is the third party
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child7"
                      checked={
                        checkedItems.child7 || checkedItems.child6 || false
                      }
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel htmlFor="child7">
                      Allow multiple users as third parties ---( assign_to)
                    </CheckboxLabel>
                  </div>
                  {(checkedItems.child7 || checkedItems.child6) && (
                    <div className="check_label left_spac">
                      <table className="group-table">
                        <thead>
                          <tr>
                            <th>Users Name</th>
                          </tr>
                        </thead>
                        {users?.map((user) => (
                          <tbody key={user.id}>
                            <tr>
                              <td
                                onClick={() =>
                                  handleItemClick("users", user.id)
                                }
                                style={{
                                  cursor: "pointer",
                                  fontWeight: selectedItems.some(
                                    (item) =>
                                      item.type === "users" &&
                                      item.id === user.id
                                  )
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                <li>{user.name}</li>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child8"
                      checked={
                        checkedItems.child8 ||
                        checkedItems.thirdPartyParentCheckbox ||
                        false
                      }
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="child8">
                      You can choose whether you need approval from all third
                      parties / just one
                    </CheckboxLabel>
                  </div>

                  <div className="dual_checkbox">
                    <div className="check_label left_spac">
                      <CheckboxInput
                        type="checkbox"
                        id="child9"
                        checked={checkedItems.child9 || false}
                        onChange={handleCheckboxChange}
                      />
                      <CheckboxLabel htmlFor="child9">One Time</CheckboxLabel>
                    </div>
                    <div className="check_label left_spac">
                      <CheckboxInput
                        type="checkbox"
                        id="child10"
                        checked={checkedItems.child10 || false}
                        onChange={handleCheckboxChange}
                      />
                      <CheckboxLabel htmlFor="child10">
                        Every time
                      </CheckboxLabel>
                    </div>
                  </div>

                  {/* <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child11"
                      checked={checkedItems.child11 || false}
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="child11">
                      To whom can transfer
                    </CheckboxLabel>
                  </div> */}

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child12"
                      checked={
                        checkedItems.child12 ||
                        checkedItems.thirdPartyParentCheckbox ||
                        false
                      }
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel
                      htmlFor="child12"
                      id="7"
                      value="Select equipment type"
                    >
                      Selection of equipment type
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child13"
                      checked={
                        checkedItems.child13 ||
                        checkedItems.child12 ||
                        checkedItems.thirdPartyParentCheckbox ||
                        false
                      }
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel
                      htmlFor="child13"
                      style={{ color: "#FF0000" }}
                    >
                      Choose between contains / does not contain
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child14"
                      checked={
                        checkedItems.child14 ||
                        checkedItems.child12 ||
                        checkedItems.thirdPartyParentCheckbox ||
                        false
                      }
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel
                      htmlFor="child14"
                      id="9"
                      value="What type of equipment"
                    >
                      What type of equipment
                    </CheckboxLabel>
                  </div>
                  {(checkedItems.child14 ||
                    checkedItems.child12 ||
                    checkedItems.thirdPartyParentCheckbox) && (
                    <div className="check_label left_spac">
                      <table className="group-table" value="product_ids">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                          </tr>
                        </thead>
                        {products?.map((product) => (
                          <tbody key={product.product_id}>
                            <tr>
                              <td
                                onClick={() =>
                                  handleItemClick(
                                    "products",
                                    product.product_id,
                                    formik
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  fontWeight: selectedItems.some(
                                    (item) =>
                                      item.type === "products" &&
                                      item.id === product.product_id
                                  )
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                <li>{product.name}</li>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                </div>
              </StyleUserForm>
              ---------------------------------------------
              <StyleUserForm>
                <div className="first_head_div left_marg">
                  <div className="trans_equipment">
                    <div className="check_label">
                      <CheckboxInput type="checkbox" id="noLimitCheckboxTwo" />
                      <CheckboxLabel
                        htmlFor="noLimitCheckboxTwo"
                        value=" Take equipment without limit and without needing
                        permission from the equipment recipient"
                      >
                        Take equipment without limit and without needing
                        permission from the equipment recipient
                      </CheckboxLabel>
                    </div>
                  </div>
                </div>
                <div className="first_head_div left_marg">
                  <div className="trans_equipment">
                    <div className="check_label">
                      <CheckboxInput type="checkbox" id="warehouseManagement" />
                      <CheckboxLabel htmlFor="warehouseManagement">
                        Warehouse Management
                      </CheckboxLabel>
                    </div>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child25"
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel htmlFor="child25" id="11">
                      Change equipment name 11
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child26"
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel htmlFor="child26" id="12">
                      Change category to equipment 12
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child27"
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />

                    <CheckboxLabel htmlFor="child27" id="13">
                      Add equipment to the warehouse 13
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child28" />
                    <CheckboxLabel htmlFor="child28">
                      Transfer equipment from the warehouse to the user
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child29" />

                    <CheckboxLabel htmlFor="child29">
                      Transfer equipment from warehouse to sub warehouse (in
                      unit)
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child30" />
                    <CheckboxLabel htmlFor="child30">
                      Receive equipment from user to warehouse
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput type="checkbox" id="child31" />
                    <CheckboxLabel htmlFor="child31">
                      Delete a product
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_hig_spac">
                    <CheckboxInput type="checkbox" id="child32" />
                    <CheckboxLabel htmlFor="child32">
                      With transfer history
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_hig_spac">
                    <CheckboxInput type="checkbox" id="child33" />
                    <CheckboxLabel htmlFor="child33">
                      Without transfer history
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_hig_spac">
                    <CheckboxInput type="checkbox" id="child34" />
                    <CheckboxLabel
                      htmlFor="vehicle1"
                      style={{ color: "#FF0000" }}
                    >
                      Give specific permissions
                    </CheckboxLabel>
                  </div>
                </div>
              </StyleUserForm>
              ------------------------------------------------
              <StyleUserForm>
                {" "}
                <div className="first_head_div left_marg">
                  <div className="trans_equipment">
                    <div className="check_label">
                      <CheckboxInput type="checkbox" id="grantPermission" />
                      <CheckboxLabel htmlFor="grantPermission">
                        To grant permissions
                      </CheckboxLabel>
                    </div>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child35" />
                    <CheckboxLabel
                      htmlFor="child35"
                      style={{ color: "#FF0000" }}
                    >
                      Give permissions that he has to other users
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child36" />
                    <CheckboxLabel
                      htmlFor="child36"
                      style={{ color: "#FF0000" }}
                    >
                      Give all permissions
                    </CheckboxLabel>
                  </div>
                </div>
                <div className="first_head_div left_marg">
                  <div className="trans_equipment">
                    <div className="check_label">
                      <CheckboxInput type="checkbox" id="adminBoard" />
                      <CheckboxLabel htmlFor="adminBoard">
                        Admin board
                      </CheckboxLabel>
                    </div>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child37"
                      checked={checkedItems.child37}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />

                    <CheckboxLabel htmlFor="child37">Add groups</CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child38"
                      checked={checkedItems.child38 || checkedItems.child37}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel htmlFor="child38" id="14">
                      Creating new groups in the unit 14
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput type="checkbox" id="child39" />

                    <CheckboxLabel
                      htmlFor="child39"
                      style={{ color: "#FF0000" }}
                    >
                      Request to annex existing groups from other units
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child40"
                      checked={checkedItems.child40}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />
                    <CheckboxLabel htmlFor="child40">Move groups</CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child41"
                      checked={checkedItems.child41 || checkedItems.child40}
                      onChange={(e) => handleCheckboxChange(e, formik)}
                    />

                    <CheckboxLabel htmlFor="child41" id="16">
                      Move groups within the unit 16
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_hig_spac">
                    <CheckboxInput type="checkbox" id="child42" />
                    <CheckboxLabel htmlFor="child42">
                      Access to a unit profile
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_hig_spac ">
                    <CheckboxInput type="checkbox" id="child43" />

                    <CheckboxLabel htmlFor="child43">
                      Unit profile control
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput type="checkbox" id="child44" />
                    <CheckboxLabel htmlFor="child44">
                      Send a request to another unit to transfer a group to it
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child45" />

                    <CheckboxLabel htmlFor="child45">
                      Invite new users to the unit
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child46" />

                    <CheckboxLabel htmlFor="child46">
                      Approve requests from users to join the unit
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput type="checkbox" id="child47" />
                    <CheckboxLabel htmlFor="child47">
                      Open a sub-warehouse
                    </CheckboxLabel>
                  </div>
                </div>
              </StyleUserForm>
            </>
            <hr />
            <div className="save_btn">
              <BlueBackgroundButton
                type="button" // Use "button" to prevent auto-submit
                onClick={() => {
                  formik.validateForm().then((errors) => {
                    const hasPermissions =
                      formik.values.permission_ids.length > 0;

                    if (Object.keys(errors).length > 0 || !hasPermissions) {
                      formik.setTouched(
                        Object.keys(errors).reduce((acc, key) => {
                          acc[key] = true;
                          return acc;
                        }, {})
                      );

                      // Show error if no permissions are selected
                      if (!hasPermissions) {
                        toast.error("Please select at least one permission.");
                      }
                    } else {
                      formik.submitForm();
                    }
                  });
                }}
                className="submit_button"
              >
                Submit
              </BlueBackgroundButton>
            </div>
          </Form>
        )}
      </Formik>
      {/* <StyleUserForm>
                <div className="first_head_div left_marg">
                  <div className="trans_equipment">
                    <div className="check_label">
                      <CheckboxInput
                        type="checkbox"
                        id="noLimitCheckbox"
                        checked={checkedItems.noLimitCheckbox || false}
                        onChange={handleCheckboxChange}
                      />

                      <CheckboxLabel htmlFor="noLimitCheckbox">
                        Transfer equipment with limitation and with
                        needing permission
                      </CheckboxLabel>
                    </div>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="requestEquipmentParentCheckbox"
                      checked={
                        checkedItems.requestEquipmentParentCheckbox || false
                      }
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="requestEquipmentParentCheckbox">
                      Request equipment
                    </CheckboxLabel>
                  </div>
                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child15"
                      checked={checkedItems.child15 || false}
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="child15">
                      Request equipment (without the need for third-party
                      approval)
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child16"
                      checked={checkedItems.child16 || false}
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="child16">
                      Choice of place: within the unit / within the selected
                      group (you can check several boxes)
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_marg">
                    <CheckboxInput
                      type="checkbox"
                      id="child17"
                      checked={checkedItems.child17 || false}
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="child17">
                      Selection of equipment type:
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child18"
                      checked={checkedItems.child18 || false}
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel
                      htmlFor="child18"
                      style={{ color: "#FF0000" }}
                    >
                      Choose between contains / does not contain
                    </CheckboxLabel>
                  </div>

                  <div className="check_label left_spac">
                    <CheckboxInput
                      type="checkbox"
                      id="child19"
                      checked={checkedItems.child19 || false}
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel htmlFor="child19">
                      What type of equipment
                    </CheckboxLabel>
                  </div>
                  <div className="first_head_div left_marg">
                    <div className="trans_equipment">
                      <div className="check_label">
                        <CheckboxInput
                          type="checkbox"
                          id="requestThirdPartyParentCheckbox"
                        />
                        <CheckboxLabel htmlFor="requestThirdPartyParentCheckbox">
                          Request equipment that requires third-party approval:
                        </CheckboxLabel>
                      </div>
                    </div>

                    <div className="check_label left_marg">
                      <CheckboxInput type="checkbox" id="child181" />
                      <CheckboxLabel htmlFor="child181">
                        Who is the third party{" "}
                        <span style={{ color: "#FF0000" }}>
                          (user search opens)
                        </span>
                      </CheckboxLabel>
                    </div>

                    <div className="check_label left_spac">
                      <CheckboxInput type="checkbox" id="child191" />
                      <CheckboxLabel htmlFor="child191">
                        Allow multiple users as third parties
                      </CheckboxLabel>
                    </div>

                    <div className="check_label left_spac">
                      <CheckboxInput type="checkbox" id="child20" />
                      <CheckboxLabel htmlFor="child20">
                        You can choose whether you need approval from all third
                        parties / just one
                      </CheckboxLabel>
                    </div>

                    <div className="check_label left_marg">
                      <CheckboxInput type="checkbox" id="child21" />
                      <CheckboxLabel htmlFor="child21">
                        Who can ask
                      </CheckboxLabel>
                    </div>

                    <div className="check_label left_marg">
                      <CheckboxInput type="checkbox" id="child22" />
                      <CheckboxLabel htmlFor="child22">
                        Selection of equipment type
                      </CheckboxLabel>
                    </div>

                    <div className="check_label left_spac">
                      <CheckboxInput type="checkbox" id="child23" />
                      <CheckboxLabel htmlFor="child23">
                        Choose between contains / does not contain
                      </CheckboxLabel>
                    </div>

                    <div className="check_label left_spac">
                      <CheckboxInput type="checkbox" id="child24" />
                      <CheckboxLabel htmlFor="child24">
                        What type of equipment
                      </CheckboxLabel>
                    </div>
                  </div>
                </div>
              </StyleUserForm>-------------------------------------------- */}
    </Root>
  );
};

const Root = styled.section`
  .profile_input {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    flex-wrap: wrap;
    .profile_edit {
      display: flex;
      position: relative;
      align-items: baseline;
      img {
        width: 120px;
        height: 120px;
        position: relative;
      }
      .edit_icon {
        background-color: transparent;
        border: none;
      }
      svg {
        cursor: pointer;
        background-color: #1814f3;
        color: #fff;
        width: 35px;
        height: 35px;
        padding: 7px;
        position: absolute;
        top: 80px;
        left: 72px;
        border-radius: 50%;
      }
    }
    .input_select {
      display: flex;
      flex: 1;
      flex-direction: column;
      .input_select_sub_div,
      .input_select_name,
      .input_select_desc,
      .input_select_unit,
      .input_select_assign,
      .input_select_group {
        display: flex;
        flex-direction: column;
        padding: 10px;
        label {
          padding: 0px 10px 10px;
          font-size: 14px;
        }
        input,
        select {
          border: 1.5px solid #dfeaf2;
          background-color: #fff;
        }
        input::placeholder,
        select::placeholder {
          font-size: 13px !important;
          font-weight: 400;
          line-height: 16px;
          color: #4b4949;
        }
      }
    }
  }

  .save_btn {
    display: flex;
    justify-content: flex-end;
  }
  .search {
    margin: 20px;
    display: flex;
    flex-direction: column;
    p {
      margin: 0px 0px 10px;
    }
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
`;

export default AddUserTab;
const StyleUserForm = styled.section`
  .main_heading {
    display: flex;
    justify-content: space-between;
  }

  .left_marg {
    margin-left: 20px;
    margin-top: 5px;
  }

  .left_spac {
    margin-left: 40px;
  }

  .left_hig_spac {
    margin-left: 50px;
  }

  .check_label {
    display: flex;
    gap: 10px;
  }

  .dual_checkbox {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;
