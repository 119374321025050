import styled from "styled-components";

export const BlueBorderButton = styled.button`
  border: 1px solid #1814f3;
  color: #1814f3;
  height: 30px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  /* background: #e5f4fb; */
  background-color: #ffffff;
  cursor: pointer;
  margin: 0px 5px;
  @media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    font-size: 12px;
    padding: 8px 12px;
  }
`;

export const BlueBorderContent = styled.button`
  border: 1px solid #1814f3;
  color: #1814f3;
  background-color: #e5f4fb;
  padding: 10px;
  border-radius: 50px;
  min-width: 100px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
  cursor: pointer;
  &.selected,
  &:hover {
    background-color: #1814f3;
    color: #ffffff;
  }
  background-color: ${({ selected }) => (selected ? "#1814f3" : "transparent")};
  color: ${({ selected }) => (selected ? "#fff" : "#1814f3")};
  @media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    font-size: 14px;
    padding: 8px;
  }
`;

export const GrayBorderButton = styled.button`
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  border: 1px solid #000000;
  background: #F0F0F0;
  height: 30px;
  color: #000000;
  border-radius: 47px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
`;
export const BlackBorderButton = styled.button`
  border: 1px solid #000000;
  border-radius: 50px;
  background: #ffffff;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
  cursor: pointer;
  padding: 5px 10px;
  &.active {
    background-color: #000000;
    color: #fff;
  }
`;

export const BlueBackgroundButton = styled.button`
  color: #ffffff;
  background-color: #1814f3;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #1814f3;
  padding: 10px;
  min-width: 165px;
  word-wrap: break-word;
`;

export const AssignNowButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #718ebf;
  color: #718ebf;
  height: 22px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #718ebf;
    color: #fff;
  }
`;
export const TabButton = styled.button`
  color: #000000;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  border: 4px solid #ffffff;
  border-bottom: 5px solid #000000;
  cursor: pointer;
  &:hover,
  &.selected {
    color: #1814f3;
    border: 4px solid #ffffff;
    border-bottom: 5px solid #1814f3;
  }
`;

export const TagButton = styled.button`
  font-size: 16px;
  font-weight: 400;
  line-height: 29.05px;
  color: #4b4949;
  background-color: #d9d9d9;
  border-radius: 50px;
  border: 1px solid transparent;
  padding: 0 20px;
`;
