import React, { useEffect, useRef, useState } from "react";
import GlobalSearch from "../Global/GlobalSearch";
import {
  AssignNowButton,
  BlackBorderButton,
  BlueBackgroundButton,
  BlueBorderButton,
  BlueBorderContent,
} from "../Global/GlobalButtons";
import styled from "styled-components";
import { Heading } from "../Global/GlobalText";
import GlobalTable from "../Global/GlobalTable";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Modal } from "react-bootstrap";
import { Pagination } from "../Global/Table2";
import useAppUtils, {
  useUserProductFetch,
  useUserSelectedList,
} from "../../utils/useAppUtils";
import {
  assignProductAction,
  assignProductByIdAction,
  getProductViewAction,
  ReconcileProductAction,
  setSelectedProductId,
} from "../../redux/users/action";
import { API_BASE_URL_USER, UPLOAD_IMAGES } from "../../utils/https";
import { FaRegHandPointer } from "react-icons/fa";
import { toast } from "react-toastify";
import userImage from "../../Assets/profile.png";
import CapIcon from "../../Assets/CapIcon.png";
import axios from "axios";

export const Product = ({ edit }) => {
  const [productAssignData, setProductAssignData] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedButton, setSelectedButton] = useState("In warehouse");
  const [selectbut, setSelected] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showReconciliation, setShowReconciliation] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const calendarRef = useRef(null);
  const [currentPageProduct, setCurrentPageProduct] = useState(1);
  const itemsPerPageProduct = 5;
  const [currentPageUser, setCurrentPageUser] = useState(1);
  const itemsPerPageUser = 5;
  const { dispatch, navigate } = useAppUtils();
  const [selectedRole, setSelectedRole] = useState("Soldier");
  const userProductFetch = useUserProductFetch();
  const usersSelect = useUserSelectedList();
  console.log("usersSelectassignProductById", usersSelect);
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };
  const handleClose = () => {
    setShowReconciliation(false);
    setShowAssign(false);
  };
  // const handleShowReconciliation = () => setShowReconciliation(true);
  const handleShowAssign = () => {
    if (selectedProductIds.length === 0) {
      toast.warn("Please select a product first."); // Show warning if no product selected
      return;
    }
    setShowAssign(true); // Show modal if products are selected
  };
  const columns = [
    { header: "Select", accessor: "select" },
    { header: "SL No ", accessor: "slno" },
    { header: "Date & Time", accessor: "create_date" },
    { header: "Image", accessor: "images" },
    { header: "Equipment Name", accessor: "name" },
    { header: "Equipment Id", accessor: "product_id" },
    { header: "Location", accessor: "location" },
    { header: "Assigned To", accessor: "username" },
    { header: "Category ", accessor: "category_name" },
    { header: "Equipment Reconciliation", accessor: "equipmentReconciliation" },
    { header: "Equipment Assign", accessor: "equipmentAssign" },
  ];
  const handleSelectChange = (rowIndex, itemType) => {
    if (itemType === "product") {
      const productId = userProductFetch[rowIndex].product_id;

      setSelectedProductIds((prev) => {
        const newSelection = prev.includes(productId)
          ? prev.filter((id) => id !== productId) // Deselect if already selected
          : [...prev, productId]; // Select if not selected

        // Log updated product selection
        console.log("Updated Selected Product IDs:", newSelection);
        return newSelection;
      });
    } else if (itemType === "user") {
      if (
        filteredPaginatedUsersSelect &&
        rowIndex < filteredPaginatedUsersSelect.length
      ) {
        const user = filteredPaginatedUsersSelect[rowIndex];
        setSelectedUsers([{ user_id: user.user_id, username: user.username }]);
      } else {
        console.error(
          "Invalid user index or paginated users data not available"
        );
      }
    }
  };
  const handleButtonSelect = (buttonIndex, button) => {
    setSelected(buttonIndex);
    setSelectedButton(button);
    if (buttonIndex === 8) {
      setShowCalendar(!showCalendar);
    } else {
      setShowCalendar(false);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);
  const handleNavigateToProduct = (product_id, fetchedId) => {
    dispatch(setSelectedProductId(product_id || fetchedId));
    const callback = (response) => {
      const fetchedId = response?.data?.data?.product_id;
      navigate(`/warehouse_manage/viewproduct/${fetchedId || product_id}`);
      console.log("Product details fetched:", setSelectedProductId);
    };
    dispatch(getProductViewAction({}, callback, product_id || fetchedId));
  };
  // Function to get paginated data
  const getPaginatedData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };
  // const paginatedUsersSelect = getPaginatedData(usersSelect);
  const paginatedProductFetch = getPaginatedData(
    userProductFetch,
    currentPageProduct,
    itemsPerPageProduct
  );
  const filteredPaginatedUsersSelect = getPaginatedData(
    usersSelect.filter((item) => item.role === selectedRole),
    currentPageUser,
    itemsPerPageUser
  );


  const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId) // Remove user ID if already selected
        : [...prevSelected, userId] // Add user ID if not selected
    );
  };
  const handleAssignClick = () => {
    console.log("productIds", selectedProductIds);
    console.log("assignedTo", selectedUsers);
    const productIds = selectedProductIds;
    const assignedTo = selectedUsers.map((user) => user.user_id);
    dispatch(assignProductAction({ productIds, assignedTo }));
  };

  const handleShowReconciliation = async () => {
    try {
      setShowReconciliation(true);
      const response = await axios.get(
        `${API_BASE_URL_USER}/productassign/${selectedProductIds}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = Array.isArray(response.data.data) ? response.data.data : [];
      setProductAssignData(data);

      dispatch(
        assignProductByIdAction(data, (callbackResponse) => {
          if (callbackResponse.status === 200) {
            console.log("Reconciliation data dispatched successfully");
          } else {
            console.error("Failed to dispatch reconciliation data");
          }
        })
      );

      console.log("API response:", data); // Check the API response in console
    } catch (error) {
      console.error("Error fetching reconciliation data", error);
    }
  };
  const handleRequestClick = async () => {
    const productId = selectedProductIds;
    const requestTo = selectedUserIds;
    try {
      const response = await axios.post(
        `${API_BASE_URL_USER}/reconcile-product`,
        { productId, requestTo },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response?.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reconciliation data", error);
    }
  };

  return (
    <Root>
      <div>
        <GlobalSearch search="Search for Something" />
      </div>
      <ButtonWrapper className="butt_div">
        {[
          "In Warehouse",
          "Outside Warehouse",
          "Equipment Reconciliation",
          "Equipment Assigned",
        ].map((button, index) => (
          <BlueBorderContent
            key={index}
            selected={selectedButton === button}
            onClick={() => handleButtonSelect(index + 1, button)}
          >
            {button}
          </BlueBorderContent>
        ))}
      </ButtonWrapper>

      <TableDiv>
        <ProductList>
          <Heading>Equipment list</Heading>
          <ButtonDiv>
            <BlueBackgroundButton
              className={selectbut === 9 ? "selected" : ""}
              onClick={() => handleButtonSelect(9)}
            >
              Export in Excel
            </BlueBackgroundButton>
          </ButtonDiv>
        </ProductList>

        {showCalendar && (
          <CalendarWrapper ref={calendarRef}>
            <Calendar onChange={setDate} value={date} />
          </CalendarWrapper>
        )}

        <div className="dash_table">
          <GlobalTable
            columns={columns}
            data={
              paginatedProductFetch
                ? paginatedProductFetch?.map((row, index) => ({
                    ...row,
                    slno: index + 1, // Adding SL No
                    create_date: formatDate(row.create_date), //Format the date here
                    images: (
                      <img
                        src={`${UPLOAD_IMAGES}/${row.image}` || CapIcon}
                        alt="Product "
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                      />
                    ),
                    location: <button className="location_btn">Inside</button>,

                    equipmentReconciliation: (
                      <BlueBorderButton
                        variant="primary"
                        onClick={handleShowReconciliation}
                      >
                        Request Now{row.equipmentReconciliation}
                      </BlueBorderButton>
                    ),
                    equipmentAssign: (
                      <BlueBorderButton
                        variant="primary"
                        onClick={handleShowAssign}
                      >
                        Request Now{row.equipmentAssign}
                      </BlueBorderButton>
                    ),
                    select: (
                      <input
                        type="checkbox"
                        checked={selectedProductIds.includes(row.product_id)} // Check if product ID is in the selectedProductIds array
                        onChange={() => handleSelectChange(index, "product")} // Handle checkbox change
                      />
                    ),
                  }))
                : []
            }
            onSelectChange={(rowIndex) =>
              handleSelectChange(rowIndex, "product")
            }
            onRowClick={(row) => handleNavigateToProduct(row.product_id)}
          />
          <Pagination
            currentPage={currentPageProduct}
            totalItems={userProductFetch.length}
            itemsPerPage={itemsPerPageProduct}
            onPageChange={(page) => setCurrentPageProduct(page)}
          />
        </div>
      </TableDiv>
      <p style={{ padding: "10px 15px", color: "red", fontSize: "12px" }}>
        *Click on Equipment Name to get its all details. <FaRegHandPointer />{" "}
      </p>

      <Modal show={showReconciliation} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <div>
            <GlobalSearch search="Search by Name,Id,Group,Unit" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <RoleSelector>
              {" "}
              <b>Select</b>
              {["Soldier", "Manager", "Commander", "Chief"].map((role) => (
                <BlackBorderButton
                  key={role}
                  className={selectedRole === role ? "active" : ""}
                  onClick={() => handleRoleSelect(role)}
                >
                  {role}
                </BlackBorderButton>
              ))}
            </RoleSelector>

            <TableWrapper>
              <Table>
                <tbody>
                  {productAssignData?.length > 0 ? (
                    productAssignData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input type="checkbox"  onChange={() => handleCheckboxChange(item.user_id)} />
                        </TableCell>
                        <TableCell>
                          <TableHeader> </TableHeader>
                          <ProfileImage
                            src={item.image || userImage}
                            alt={item.name}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <TableHeader>Name</TableHeader>
                          {item.assigned_by_username}
                        </TableCell>
                        <TableCell>
                          <TableHeader>Profile</TableHeader>
                          {item.profile}
                        </TableCell>
                        <TableCell>
                          <TableHeader>ID</TableHeader>
                          {item.user_id}
                        </TableCell>
                        <TableCell>
                          <TableHeader>Group</TableHeader>
                          {item.group_name}
                        </TableCell>
                        <TableCell>
                          {" "}
                          <TableHeader>Unit</TableHeader>
                          {item.unit_name}
                        </TableCell>
                        <TableCell>
                          {" "}
                          <TableHeader>Action</TableHeader>
                          <AssignNowButton
                            onClick={() => {
                              handleRequestClick();
                              handleClose();
                            }}
                          >
                            Request now
                          </AssignNowButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No data available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </TableWrapper>
          </Container>
        </Modal.Body>
      </Modal>
      {selectedProductIds && (
        <Modal show={showAssign} onHide={handleClose} size="xl" centered>
          <Modal.Header closeButton>
            <div>
              <GlobalSearch search="Search by Name,Id,Group,Unit" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <RoleSelector>
                {" "}
                <b>Select</b>
                {["Soldier", "Manager", "Commander", "Chief"].map((role) => (
                  <BlackBorderButton
                    key={role}
                    className={selectedRole === role ? "active" : ""}
                    onClick={() => handleRoleSelect(role)}
                  >
                    {role}
                  </BlackBorderButton>
                ))}
              </RoleSelector>

              <TableWrapper>
                <Table>
                  <thead>
                    <TableRow>
                      <TableHeader></TableHeader>
                      <TableHeader>Profile Image</TableHeader>
                      <TableHeader>Username</TableHeader>
                      <TableHeader>Profile</TableHeader>
                      <TableHeader>ID</TableHeader>
                      <TableHeader>Group</TableHeader>
                      <TableHeader>Unit</TableHeader>
                      <TableHeader>Action</TableHeader>
                      <TableHeader>Edit</TableHeader>
                    </TableRow>
                  </thead>
                  <tbody>
                    {filteredPaginatedUsersSelect.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input
                            type="checkbox"
                            checked={selectedUsers.some(
                              (selectedUser) =>
                                selectedUser.user_id === item.user_id
                            )}
                            onChange={(event) =>
                              handleSelectChange(
                                index,
                                "user",
                                event.target.checked
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <ProfileImage
                            src={item.image || userImage}
                            alt={item.username}
                          />
                        </TableCell>
                        <TableCell>{item.username}</TableCell>
                        <TableCell>{item.profile || "N/A"}</TableCell>
                        <TableCell>{item.user_id}</TableCell>
                        <TableCell>{item.group_name}</TableCell>
                        <TableCell>{item.unit_name || "N/A"}</TableCell>
                        <TableCell>
                          <AssignNowButton
                            onClick={() => {
                              handleAssignClick();
                              handleClose();
                            }}
                          >
                            {" "}
                            Assign now
                          </AssignNowButton>
                        </TableCell>
                        <TableCell>
                          <img src={edit} alt="edit" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableWrapper>
              <Pagination
                currentPage={currentPageUser}
                totalItems={
                  usersSelect.filter((item) => item.role === selectedRole)
                    .length
                }
                itemsPerPage={itemsPerPageUser}
                onPageChange={(page) => setCurrentPageUser(page)}
              />
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </Root>
  );
};

const Root = styled.section`
  .butt_div {
    justify-content: unset;
  }

  .location_btn {
    border: 1px solid #1814f3;
    color: #1814f3;
    height: 30px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;

    background-color: #ffffff;
    cursor: pointer;
    margin: 0px 5px;
    padding: 0 20px;
  }
`;
export const ButtonWrapper = styled.div`
  margin: 10px 0px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ProductList = styled.div`
  padding: 20px 0px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ButtonDiv = styled.div`
  display: flex;
  gap: 20px;
`;

export const TableDiv = styled.section`
  display: flex;
  flex-direction: column;
  .dash_table {
    background-color: #ffff;
    border-radius: 25px;
    padding: 8px;
    overflow: auto;
  }
`;

const CalendarWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .react-calendar {
    position: absolute;
    z-index: 11;
    border-radius: 10px;
    border: 2px solid #fff;
    box-shadow: 1px 2px 7px 1px gray;
  }
`;

const Container = styled.div`
  padding: 20px;
  background-color: #f0f8ff;
`;

const RoleSelector = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #eaf4fc;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #f0f8ff;
  font-weight: bold;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #ffffff;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  text-align: left;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
