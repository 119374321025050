import React, { useEffect, useState } from "react";
import GlobalSearch from "../Global/GlobalSearch";
import styled from "styled-components";
import { Heading, MainHeading, ProfileCount } from "../Global/GlobalText";
import { FaUser } from "react-icons/fa";
import { BlueBorderContent } from "../Global/GlobalButtons";
import DashChart from "../Chart/DashChart";
import { useCategoryList, usePermission } from "../../utils/useAppUtils";
import { UPLOAD_IMAGES } from "../../utils/https";
import { Pagination } from "../Global/Table2";

function Dashboard() {
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectbut, setSelected] = useState(1);
  const permission = usePermission();
  const categories = useCategoryList();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust items per page as needed
  const totalItems = categories.length;
  console.log("categories", categories);
  useEffect(() => {}, [permission]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectChange = (itemId) => {
    // Implement your selection logic here, e.g., update selectedItems state
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const handleButtonSelect = (buttonIndex) => {
    setSelected(buttonIndex);
  };
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const dataToDisplay = categories.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ); 
  return (
    <Root>
      {/* <GlobalSearch  search="Search By Name, ID, Group, Unit" /> */}
      <div className="dash_main_div">
        <Heading>Statistics</Heading>
        <div className="chart_main_div">
          <div className="chart_div">
            <DashChart />
          </div>
          <div className="unite_profile_div">
            <div className="unite_content">
              <div className="icon_div">
                <FaUser />
              </div>
              <MainHeading>Unite Profile</MainHeading>
              <ProfileCount>10,000</ProfileCount>
            </div>

            <div>
              <GlobalSearch search="Search for something" />
            </div>

            <div className="button_div">
              <BlueBorderContent
                className={selectedButton === 1 ? "selected" : ""}
                onClick={() => handleButtonClick(1)}
              >
                In ware house
              </BlueBorderContent>
              <BlueBorderContent
                className={selectedButton === 2 ? "selected" : ""}
                onClick={() => handleButtonClick(2)}
              >
                Outside Warehouse
              </BlueBorderContent>
              <BlueBorderContent
                className={selectedButton === 3 ? "selected" : ""}
                onClick={() => handleButtonClick(3)}
              >
                Equipment Reconciliation
              </BlueBorderContent>
              <BlueBorderContent
                className={selectedButton === 4 ? "selected" : ""}
                onClick={() => handleButtonClick(4)}
              >
                Equipment Assigned
              </BlueBorderContent>
              <BlueBorderContent
                className={selectedButton === 5 ? "selected" : ""}
                onClick={() => handleButtonClick(5)}
              >
                Category
              </BlueBorderContent>
              <BlueBorderContent
                className={selectedButton === 6 ? "selected" : ""}
                onClick={() => handleButtonClick(6)}
              >
                Product
              </BlueBorderContent>
              <BlueBorderContent
                className={selectedButton === 7 ? "selected" : ""}
                onClick={() => handleButtonClick(7)}
              >
                User
              </BlueBorderContent>
            </div>
          </div>
        </div>

        <div className="table_div">
          <div className="head_div">
            <Heading>Category list</Heading>
            <div className="weak_date">
              <BlueBorderContent
                className={selectbut === 1 ? "selected" : ""}
                onClick={() => handleButtonSelect(1)}
              >
                Week to Date
              </BlueBorderContent>
              <BlueBorderContent
                className={selectbut === 2 ? "selected" : ""}
                onClick={() => handleButtonSelect(2)}
              >
                Export in Excel
              </BlueBorderContent>
            </div>
          </div>
          <div className="dash_table">
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>Select</th>
                  <th>SL No</th>
                  <th>Date & Time</th>
                  <th>Category</th>
                  <th>Image</th>
                  <th>Product Quantity</th>
                </tr>
              </thead>
              <tbody>
                {dataToDisplay?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleSelectChange(item.id)}
                      />
                    </TableCell>
                    <TableCell>{index + 1 + (currentPage - 1) * itemsPerPage}</TableCell>
                    <TableCell>{item.create_date}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <img src={`${UPLOAD_IMAGES}/${item.image}`} alt="Category" />
                    </TableCell>
                    <TableCell>{item.quantity || 0}</TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
            <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={onPageChange}
          />
          </div>
        </div>
      </div>
    </Root>
  );
}

export default Dashboard;

const Root = styled.section`
  .dash_main_div {
    display: flex;
    gap: 30px;
    flex-direction: column;

    .chart_main_div {
      display: flex;
      gap: 20px;
      @media (max-width: 567px) {
        flex-wrap: wrap;
      }
      @media (min-width: 567px) and (max-width: 992px) {
        flex-wrap: wrap;
      }
      .chart_div {
        width: 35%;
        background-color: #ffffff;
        border-radius: 25px;

        @media (max-width: 567px) {
          width: 100%;
        }
        @media (min-width: 567px) and (max-width: 992px) {
          width: 100%;
        }
      }
      .unite_profile_div {
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 567px) {
          width: 100%;
        }
        @media (min-width: 567px) and (max-width: 992px) {
          width: 100%;
        }
        .unite_content {
          background-color: #ffffff;
          border-radius: 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          .icon_div {
            svg {
              padding: 5px;
              background: #e7edff;
              border-radius: 50px;
              color: #396aff;
              height: 25px;
              width: 25px;
            }
          }
        }

        .button_div {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    .table_div {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .head_div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 567px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }
        .weak_date {
          display: flex;
          justify-content: flex-end;
          gap: 20px;
          @media (max-width: 567px) {
            display: flex;
            justify-content: center;
            gap: 20px;
            width: 100%;
          }
        }
      }

      .dash_table {
        background-color: #ffff;
        border-radius: 25px;
        padding: 8px;
        overflow: auto;
      }
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    color: #718ebf;
  }
  td {
    color: #232323;
  }

  tr {
    border-bottom: 1px solid #e6eff5;
  }

  th,
  td {
    text-align: left;
    padding: 10px 5px;
    text-align: center;
    font-size: 13px;
  }

  img {
    width: 50px;
    height: 35px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    /* Adjust table layout for smaller screens */
    th,
    td {
      display: block;
      width: 100%;
    }

    th {
      text-align: left;
    }

    td {
      text-align: right;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  // Add your custom styles for table headers
`;

const TableCell = styled.td`
  // Add your custom styles for table cells
`;
