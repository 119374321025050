import React from "react";
import GlobalUserForm from "../Global/GlobalUserForm";
import styled from "styled-components";
import GlobalSearch from "../Global/GlobalSearch";
import { FormTextBlack, Textlabel } from "../Global/GlobalText";
import { BorderInput, BorderSelect } from "../Global/GlobalPlaceholder";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

export const Hierarchy = () => {
  return (
    <Root>
      <FormTextBlack>
        {" "}
        <HiOutlineArrowNarrowLeft />
        Add Staff
      </FormTextBlack>
      <div className="profile_input">
        <div className="input_select">
          <div className="input_select_name">
            <Textlabel htmlFor="first name">First Name</Textlabel>
            <BorderInput placeholder="XYZ " />
          </div>
          <div className="input_select_desc">
            <Textlabel htmlFor="group">Group</Textlabel>
            <BorderSelect>
              <option>CharleneReed</option>
              <option>CharleneReed</option>

            </BorderSelect>
          </div>
        </div>
        <div className="input_select">
          <div className="input_select_assign">
            <Textlabel htmlFor="last name">Last Name</Textlabel>
            <BorderInput placeholder="xyz" />
          </div>
          <div className="input_select_unit">
            <Textlabel htmlFor="unit">Unit</Textlabel>
            <BorderSelect>
              <option>Admin can assign unit from here</option>
              <option>Admin can assign unit from here</option>

            </BorderSelect>
          </div>
        </div>
        <div className="input_select">
          <div className="input_select_group">
           <Textlabel htmlFor="email">E-mail</Textlabel>
            <BorderInput placeholder="logstix@gmail.com" />
          </div>
          <div className="input_select_sub_div">
           <Textlabel htmlFor="role">Role</Textlabel>
            <BorderSelect>
              <option>CharleneReed</option>
              <option>CharleneReed</option>

            </BorderSelect>
          </div>
        </div>
      </div>

      <div className="search">
        <FormTextBlack>Select Permission</FormTextBlack>
        <GlobalSearch search="Search for something" />
      </div>
      <>
        <GlobalUserForm />
      </>
    </Root>
  );
};
const Root = styled.section`
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  .profile_input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    flex-wrap: wrap;
    .input_select {
      display: flex;
      flex: 1;
      flex-direction: column;
      .input_select_sub_div,
      .input_select_name,
      .input_select_desc,
      .input_select_unit,
      .input_select_assign,
      .input_select_group {
        display: flex;
        flex-direction: column;
        padding: 10px;
        label {
          padding: 0px 10px 10px;
          font-size: 14px;
        }
        input,
        select {
          border: 1.5px solid #dfeaf2;
          background-color: #fff;
        }
        input::placeholder,
        select::placeholder {
          font-size: 13px !important;
          font-weight: 400;
          line-height: 16px;
          color: #4b4949;
        }
      }
    }
  }
  .search {
    margin: 20px;
    display: flex;
    flex-direction: column;
    p {
      margin: 0px 0px 10px;
    }
  }
`;
