// Filename - pages/Support.js
import React, { useState } from "react";
import styled from "styled-components";
import {
  AssignNowButton,
  BlueBackgroundButton,
  BlueBorderContent,
} from "../Global/GlobalButtons";
import GlobalSearch from "../Global/GlobalSearch";
import GlobalTable from "../Global/GlobalTable";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Modal } from "react-bootstrap";
import { CheckboxLabel } from "../Global/GlobalPlaceholder";
import profile from "../../Assets/profile.png";
import { GroupHead, GroupSubHead } from "../Global/GlobalText";
import useAppUtils, { useUnitList } from "../../utils/useAppUtils";
import { useDispatch } from "react-redux";
import { DeleteUnitAction, setSelectedUnitId } from "../../redux/users/action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const UniteProfile = () => {
  const [selectedButton, setSelectedButton] = useState();

  const [showAssignProduct, setShowAssignProduct] = useState(false);

  const [showReconciliation, setShowReconciliation] = useState(false);

  const [data, setData] = useState([]);

  const units = useUnitList();

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "SL No", accessor: "slNo" },
    { header: " Create Date & Time", accessor: "createdateTime" },
    { header: "Created By", accessor: "createdby" },
    // { header: "Group List", accessor: "grouplist" },
    { header: "Unit List", accessor: "unitlist" },
    { header: "Member List", accessor: "memberlist" },
    { header: "Equipment List", accessor: "equipmentlist" },
    { header: "Remove", accessor: "remove" },
  ];

  const handleClose = () => {
    setShowReconciliation(false);
    setShowAssignProduct(false);
  };

  const handleShowAssignProduct = () => setShowAssignProduct(true);

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const handleSelectChange = (rowIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].selected = !newData[rowIndex].selected;
      return newData;
    });
  };

  const groups = [
    { id: 1, groupName: "Group A", groupSub: "ABC", unitName: "XYZ" },
    { id: 2, groupName: "Group B", groupSub: "DEF", unitName: "LMN" },
    { id: 3, groupName: "Group C", groupSub: "GHI", unitName: "OPQ" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const {dispatch ,navigate}= useAppUtils();
  

  const handleDeleteUnit = (unitId) => {
    dispatch(
      DeleteUnitAction({ unitId }, (response) => {
        if (response?.status === 200) {
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
  const handleNavigateToUnit = (unitId) => {
    dispatch(setSelectedUnitId(unitId)); // Store unit_id in Redux
    navigate(`/unit_userlist/${unitId}`); // Navigate to the new page with unit_id
  };
  return (
    <Root>
      <div className="save_btn">
        <BlueBackgroundButton onClick={()=>{navigate("/group_create")}}>Create Group</BlueBackgroundButton>
        <BlueBackgroundButton  onClick={()=>{navigate("/group_create")}}>Create Unit</BlueBackgroundButton>
      </div>

      <div className="search_product_div">
        <GlobalSearch search="Search for something" />
      </div>

      <div className="button_div">
        <BlueBorderContent
          className={selectedButton === 1 ? "selected" : ""}
          onClick={() => handleButtonClick(1)}
        >
          Group
        </BlueBorderContent>

        <BlueBorderContent
          className={selectedButton === 2 ? "selected" : ""}
          onClick={() => handleButtonClick(2)}
        >
          Unit
        </BlueBorderContent>

        <BlueBorderContent
          className={selectedButton === 3 ? "selected" : ""}
          onClick={() => handleButtonClick(3)}
        >
          Created Date
        </BlueBorderContent>

        <BlueBorderContent
          className={selectedButton === 4 ? "selected" : ""}
          onClick={() => handleButtonClick(4)}
        >
          Name
        </BlueBorderContent>

        <BlueBorderContent
          className={selectedButton === 5 ? "selected" : ""}
          onClick={() => handleButtonClick(5)}
        >
          Position
        </BlueBorderContent>
      </div>

      <div className="change_location_btn">
        <BlueBackgroundButton onClick={handleShowAssignProduct}>
          Change Group Location
        </BlueBackgroundButton>
      </div>

      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Select</TableHeader>
              <TableHeader>SL No</TableHeader>
              <TableHeader>Create Date & Time</TableHeader>
              <TableHeader>Created By</TableHeader>
              <TableHeader>Unit List</TableHeader>
              <TableHeader>Member List</TableHeader>
              <TableHeader>Equipment List</TableHeader>
              <TableHeader>Remove</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {units.map((unit, index) => (
              <TableRow key={unit.unit_id}>
                <TableCell>
                  <input type="checkbox" />
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {unit.create_date ? formatDate(unit.create_date) : "No Data"}
                </TableCell>
                <TableCell>{unit.role || "No Role"}</TableCell>
                <TableCell>{unit.unit_name || "No Unit"}</TableCell>
                <TableCell>{unit.user_count || "No Member"}</TableCell>
                <TableCell
                    onClick={() => handleNavigateToUnit(unit.unit_id)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {unit.equipment_permission_count || "No Equipment"}
                </TableCell>
                <TableCell>
                  <RiDeleteBin5Fill
                    className="delete_button"
                    onClick={() => handleDeleteUnit(unit.unit_id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      <StyledModal
        show={showAssignProduct}
        onHide={handleClose}
        className="group_location_modal"
        centered
      >
        <Modal.Header closeButton>
          <div className="select_search">
            <div className="check_select">
              <input type="checkbox" />
              <CheckboxLabel htmlFor="Select All">Select All</CheckboxLabel>
            </div>

            <div className="search_div">
              <GlobalSearch search="Group, Unit" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="check_main_div">
            <div className="checkout_div">
              {groups.map((group, index) => (
                <div className="group_div" key={group.id}>
                  <input type="checkbox" />
                  <div className="group_cont">
                    <img src={profile} alt="img" />
                    <div className="group_sub_cont">
                      <GroupHead>{group.groupName}</GroupHead>
                      <GroupSubHead>{group.groupSub}</GroupSubHead>
                    </div>
                    <div className="group_sub_cont">
                      <GroupHead>Unit</GroupHead>
                      <GroupSubHead>{group.unitName}</GroupSubHead>
                    </div>
                    <AssignNowButton>Move Here</AssignNowButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </StyledModal>
    </Root>
  );
};

export default UniteProfile;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #e5f4fb;
  }

  .select_search {
    display: flex;
    gap: 0px;
    .check_select {
      width: 25%;
      gap: 10px;
      display: flex;
      align-items: center;
    }
    .search_div {
      width: 80%;
      gap: 30px;
    }
  }

  .check_main_div {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .checkout_div {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .group_div {
      display: flex;
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      align-items: center;
      gap: 20px;

      .group_cont {
        display: flex;
        justify-content: space-around;
        width: 100%;
        align-items: center;
        .group_sub_cont {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;

const Root = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;

  .save_btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .button_div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    button {
      min-width: 150px;
    }
  }

  .change_location_btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .delete_button {
    width: 30px;
    height: 30px;
    color: #fc0005;
    cursor: pointer;
  }

  .modal-content {
    background: #e5f4fb;
  }
`;

// Styled Components
const Container = styled.div`
  padding: 20px;
  background-color: #e6f7ff;
  min-height: 100vh;
  font-family: Arial, sans-serif;
`;

const TableWrapper = styled.div`
  overflow-x: auto; // Make the table scrollable on smaller screens
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #e3f2fd;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const DeleteIcon = styled.div`
  color: #ff4d4f;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  &:hover {
    color: #ff1a1a;
  }
`;
