import React, { useState } from "react";
import styled from "styled-components";
import {
  BlackBorderButton,
  BlueBorderContent,
  TabButton,
} from "../Global/GlobalButtons";
import { GrEdit } from "react-icons/gr";
import { BorderInput, BorderSelect } from "../Global/GlobalPlaceholder";
import { Textlabel } from "../Global/GlobalText";
import userImage from "../../Assets/ProfileIcon.png";
import { ButtonWrapper } from "../warehouse/Product";
import GlobalSearch from "../Global/GlobalSearch";
import GlobalTable from "../Global/GlobalTable";

export const History = () => {
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [selectedButton, setSelectedButton] = useState("User List");
  const [data, setData] = useState([
    {
      selected: false,
      srNo: 1,
      JoinDateTime: "June 20, 2024",
      image: "CapIcon",
      Position: "Manager",
      userName: "Test2",
      CurrentUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      CurrentGroup: <BlueBorderContent>group name</BlueBorderContent>,
      LeftDateTime: "June 20, 2024",
      LastUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      LastGroup: <BlueBorderContent>group name</BlueBorderContent>,
    },
    {
      selected: false,
      srNo: 2,
      JoinDateTime: "June 20, 2024",
      image: "CapIcon",
      Position: "Manager",
      userName: "Test2",
      CurrentUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      CurrentGroup: <BlueBorderContent>group name</BlueBorderContent>,
      LeftDateTime: "June 20, 2024",
      LastUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      LastGroup: <BlueBorderContent>group name</BlueBorderContent>,
    },
  ]);

  const [value, setValue] = useState([
    {
      selected: false,
      srNo: 1,
      JoinDateTime: "June 20, 2024",
      image: "CapIcon",
      GroupManagerName: "Test2",
      CurrentUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      GroupStrength:344,
      LeftDateTime: "June 20, 2024",
      LastUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      LastReportingManager:"Soldier",
    },
    {
      selected: false,
      srNo: 2,
      JoinDateTime: "June 20, 2024",
      image: "CapIcon",
      GroupManagerName: "Test2",
      CurrentUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      GroupStrength:  200 ,
      LeftDateTime: "June 20, 2024",
      LastUnit: <BlackBorderButton>User 2</BlackBorderButton>,
      LastReportingManager: "Manager",
    },
  ]);

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "Sr.No", accessor: "srNo" },
    { header: "Join Date & Time", accessor: "JoinDateTime" },
    { header: "Equipment List", accessor: "image" },
    { header: "Position ", accessor: "Position" },
    { header: "Name", accessor: "userName" },
    { header: "Current Unit ", accessor: "CurrentUnit" },
    { header: "Current Group ", accessor: "CurrentGroup" },
    { header: "Left Date & Time", accessor: "LeftDateTime" },
    { header: "Last Unit ", accessor: "LastUnit" },
    { header: "Last Group ", accessor: "LastGroup" },
  ];
  const column = [
    { header: "Select", accessor: "select" },
    { header: "Sr.No", accessor: "srNo" },
    { header: "Join Date & Time", accessor: "JoinDateTime" },
    { header: "Equipment List", accessor: "image" },
    { header: "Group Manager Name", accessor: "GroupManagerName" },
    { header: "Current Unit ", accessor: "CurrentUnit" },
    { header: "Group Strength", accessor: "GroupStrength" },
    { header: "Left Date & Time", accessor: "LeftDateTime" },
    { header: "Last Unit ", accessor: "LastUnit" },
    { header: "Last Reporting Manager ", accessor: "LastReportingManager" },
  ];

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleButtonSelect = (button) => {
    setSelectedButton(button);
  };

  return (
    <Root>
      <div className="tab_div">
        <TabButton
          className={selectedTab === "tab1" ? "selected" : ""}
          onClick={() => handleTabClick("tab1")}
        >
          User History
        </TabButton>

        <TabButton
          className={selectedTab === "tab2" ? "selected" : ""}
          onClick={() => handleTabClick("tab2")}
        >
          Group History
        </TabButton>
      </div>
      <div style={{padding:"0px 20px"}}>
        <div>
          <GlobalSearch search="Search for Something" />
        </div>
        <ButtonWrapper>
          <BlueBorderContent
            selected={selectedButton === "User List"}
            onClick={() => handleButtonSelect(1, "User List")}
          >
            User List
          </BlueBorderContent>
          <BlueBorderContent
            selected={selectedButton === "Equipment List"}
            onClick={() => handleButtonSelect(2, "Equipment List")}
          >
            Equipment List
          </BlueBorderContent>
          <BlueBorderContent
            selected={selectedButton === "Join Date & Time"}
            onClick={() => handleButtonSelect(3, "Join Date & Time")}
          >
            Join Date & Time
          </BlueBorderContent>
          <BlueBorderContent
            selected={selectedButton === "Left Date & time"}
            onClick={() => handleButtonSelect(4, "Left Date & time")}
          >
            Left Date & time
          </BlueBorderContent>
          <BlueBorderContent
            selected={selectedButton === "Group"}
            onClick={() => handleButtonSelect(5, "Group")}
          >
            Group
          </BlueBorderContent>
          <BlueBorderContent
            selected={selectedButton === "Unit"}
            onClick={() => handleButtonSelect(6, "Unit")}
          >
            Unit
          </BlueBorderContent>
          <BlueBorderContent
            selected={selectedButton === "Position"}
            onClick={() => handleButtonSelect(7, "Position")}
          >
            Position
          </BlueBorderContent>
        </ButtonWrapper>
      </div>

      <div className="table">
        {selectedTab === "tab1" ? (
          <CreateGroup>
            <div className="dash_table">
              <GlobalTable
                columns={columns}
                data={data.map((row, index) => ({
                  ...row,
                }))}
              />
            </div>
          </CreateGroup>
        ) : selectedTab === "tab2" ? (
            <CreateGroup>
            <div className="dash_table">
              <GlobalTable
                columns={column}
                data={value.map((row, index) => ({
                  ...row,
                }))}
              />
            </div>
          </CreateGroup>
        ) : (
          <CreateGroup>
            <div className="profile_input">
              <div className="profile_edit">
                <img src={userImage} alt="profile edit" />
                <button className="edit_icon">
                  <GrEdit />
                </button>
              </div>

              <div className="input_select">
                <div className="input_select_group">
                  <Textlabel htmlFor="group name">Group Name</Textlabel>
                  <BorderInput placeholder="Group Name" />
                </div>

                <div className="input_select_sub_div">
                  <Textlabel htmlFor="unit">Unit</Textlabel>
                  <BorderSelect>
                    <option>CharleneReed</option>
                    <option>CharleneReed</option>
                  </BorderSelect>
                </div>
              </div>
            </div>
          </CreateGroup>
        )}
      </div>
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;

  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px 20px 0px;
  }

  .table {
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
`;
const CreateGroup = styled.section`
  .profile_input {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    flex-wrap: wrap;
    .profile_edit {
      display: flex;
      position: relative;
      align-items: baseline;
      img {
        width: 130px;
        height: 140px;
        position: relative;
      }
      .edit_icon {
        background-color: transparent;
        border: none;
      }
      svg {
        cursor: pointer;
        background-color: #1814f3;
        color: #fff;
        width: 35px;
        height: 35px;
        padding: 7px;
        position: absolute;
        top: 80px;
        left: 72px;
        border-radius: 50%;
      }
    }
    .input_select {
      display: flex;
      flex: 1;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-top: 20px;
      }
      .input_select_sub_div,
      .input_select_group {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px;
        label {
          padding: 0px 10px 10px;
          font-size: 14px;
        }
        input,
        select {
          border: 1.5px solid #dfeaf2;
          background-color: #fff;
        }
        input::placeholder,
        select::placeholder {
          font-size: 13px !important;
          font-weight: 400;
          line-height: 16px;
          color: #4b4949;
        }
      }
    }
  }
`;
