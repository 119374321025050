import React, { useState } from "react";
import GlobalSearch from "../Global/GlobalSearch";
import { BlueBorderContent } from "../Global/GlobalButtons";
import styled from "styled-components";
import GlobalTable from "../Global/GlobalTable";
import "react-calendar/dist/Calendar.css";
import { useGroupList } from "../../utils/useAppUtils";

export const AllGroup = () => {
  const [selectedButton, setSelectedButton] = useState("Group");
  const [data, setData] = useState([
    {
      slNo: 1,
      dateTime: "2023-08-28 12:34",
      createdBy: "Admin",
      groupList: "Group Name",
      unitList: "Unit Name",
      memberList: "200",
      equipmentList: "200",
      selected: false,
    }
  
  ]);

  const groups = useGroupList();

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "SL No", accessor: "slNo" },
    { header: "Create Date & Time", accessor: "dateTime" },
    { header: "Created By", accessor: "createdBy" },
    { header: "Group List", accessor: "groupList" },
    { header: "Unit List", accessor: "unitList" },
    { header: "Member List", accessor: "memberList" },
    { header: "equipment List", accessor: "equipmentList" },
  ];

  const handleSelectChange = (rowIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].selected = !newData[rowIndex].selected;
      return newData;
    });
  };

  const handleButtonSelect = (buttonIndex, button) => {
    setSelectedButton(button);
    
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div>
        <GlobalSearch search="Search for Something" />
      </div>
      <ButtonWrapper>
        <BlueBorderContent
          selected={selectedButton === "Group"}
          onClick={() => handleButtonSelect(1, "Group")}
        >
          Group
        </BlueBorderContent>
        <BlueBorderContent
          selected={selectedButton === "Unit"}
          onClick={() => handleButtonSelect(2, "Unit")}
        >
          Unit
        </BlueBorderContent>
        <BlueBorderContent
          selected={selectedButton === "Created Date"}
          onClick={() => handleButtonSelect(3, "Created Date")}
        >
          Created Date
        </BlueBorderContent>
        <BlueBorderContent
          selected={selectedButton === "Action"}
          onClick={() => handleButtonSelect(4, "Action")}
        >
          Action
        </BlueBorderContent>
      </ButtonWrapper>

      <TableDiv>
        <div className="dash_table">
          <GlobalTable
            columns={columns}

            data={groups.map((group, index) => ({
              slNo: index + 1,
              dateTime: group.create_date ? formatDate(group.create_date) : "No Data",
              createdBy: group.role || "No Role" , 
              groupList: group.group_name || "No Data",
              unitList: `Unit ${group.unitId}` || "No Data", 
              memberList: group.total_members || "No Data", 
              equipmentList: "No Data"
            }))}

            onSelectChange={handleSelectChange}
          />
        </div>
      </TableDiv>
    </>
  );
};

const ButtonWrapper = styled.div`
  margin: 10px 0px 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ProductList = styled.div`
  padding: 20px 0px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ButtonDiv = styled.div`
  display: flex;
  gap: 20px;
`;

export const TableDiv = styled.section`
  display: flex;
  flex-direction: column;
  .dash_table {
    background-color: #ffff;
    border-radius: 25px;
    padding: 8px;
    overflow: auto;
  }
`;
