// Filename - pages/ContactUs.js

import React from "react";
import {
  ProfileImage,
  TableCell,
  TableDiv,
  TableRow,
} from "../warehouse/Product";
import { BlueBorderButton } from "../Global/GlobalButtons";
import { RootTable } from "../Global/GlobalTable";
import userImage from "../../Assets/profile.png";
import { useRequestEquipment } from "../../utils/useAppUtils";

const RequestEquipment = () => {
  const requestEquipment = useRequestEquipment();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  return (
    <div className="contact">
      <TableDiv>
        <div className="dash_table">
          <RootTable>
            <table>
              <thead>
                <tr>
                  <th>SL No</th>
                  <th>Date & Time</th>
                  <th>Image</th>
                  <th> Name</th>
                  <th>Product ID</th>
                  <th>Category</th>
                  <th>Profile</th>
                  <th>Requested By / Unit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {requestEquipment.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <input
                        type="checkbox"
                        // checked={selectedUsers.some(
                        //   (selectedUser) =>
                        //     selectedUser.user_id === item.user_id
                        // )}
                        // onChange={(event) =>
                        //   handleSelectChange(

                        //     event.target.checked
                        //   )
                        // }
                      />
                    </TableCell>
                    <TableCell>
                      {formatDate(item.create_date || "N/A")}
                    </TableCell>

                    <TableCell>
                      <ProfileImage
                        src={item.image || userImage}
                        alt={item.username}
                      />
                    </TableCell>
                    <TableCell>{item.username}</TableCell>
                    <TableCell>{item.productId || "N/A"}</TableCell>
                    <TableCell>{item.categoryname}</TableCell>
                    <TableCell>{item.username}</TableCell>
                    <TableCell>{item.requestbyRole || "N/A"}</TableCell>
                    <TableCell>
                      <BlueBorderButton variant="primary">
                        {item.status}
                      </BlueBorderButton>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </table>
          </RootTable>
        </div>
      </TableDiv>
    </div>
  );
};

export default RequestEquipment;
