import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CheckboxInput, CheckboxLabel } from "../../Global/GlobalPlaceholder";
import {
  useGroupList,
  useProductList,
  useUnitList,
} from "../../../utils/useAppUtils";
import { useDispatch, useSelector } from "react-redux";
import { addSelection, removeSelection } from "./action";

export default function Dummy({ checkedItems, setCheckedItems }) {
  const dispatch = useDispatch();
  const [permissionIds, setPermissionIds] = useState([]); // State for selected permissions
  const [productIds, setProductIds] = useState([]);
  const selectedItems = useSelector(
    (state) => state.selection.selectedItems || []
  );

  const groups = useGroupList();
  const units = useUnitList();
  const products = useProductList();
console.log("groups units products",groups,units,products)

const handleCheckboxChange = (e) => {
  const { id, checked } = e.target;

  // Update permission_ids based on selections
  if (id === "child2_1" && checked) {
    setPermissionIds((prev) => [...prev, '1']); // Add permission ID for Group
  } else if (id === "child2_2" && checked) {
    setPermissionIds((prev) => [...prev, '2']); // Add permission ID for Unit
  }

  // Update other states accordingly...
  setCheckedItems((prev) => ({
    ...prev,
    [id]: checked,
    ...(id === "selectAll" && Object.fromEntries(Object.keys(prev).map((key) => [key, checked]))),
  }));
};


const handleItemClick = (itemType, itemId) => {
  if (!selectedItems) return;

  const isSelected = selectedItems.some((item) => item.type === itemType && item.id === itemId);

  const payload = { type: itemType, id: itemId };

  if (isSelected) {
    dispatch(removeSelection(payload));
    if (itemType === "products") {
      setProductIds((prev) => prev.filter(id => id !== itemId));
    }
  } else {
    dispatch(addSelection(payload));
    if (itemType === "products") {
      setProductIds((prev) => [...prev, itemId]);
    }
  }
};

  useEffect(() => {
    const logSelectedNames = () => {
      const selectedGroups = selectedItems
        .filter((item) => item.type === "groups")
        .map((item) => groups.find((group) => group.id === item.id)?.group_name);
  
      const selectedUnits = selectedItems
        .filter((item) => item.type === "units")
        .map((item) => units.find((unit) => unit.id === item.id)?.unit_name);
  
      const selectedProducts = selectedItems
        .filter((item) => item.type === "products")
        .map((item) => products.find((product) => product.id === item.id)?.name);
  
      console.log("Selected Groups:", selectedGroups);
      console.log("Selected Units:", selectedUnits);
      console.log("Selected Products:", selectedProducts);
    };
    logSelectedNames();
  }, [selectedItems,groups,units,products]);

 
  return (
    <StyleUserForm>
      <div className="main_heading">
        <div className="check_label">
          <CheckboxInput
            type="checkbox"
            id="selectAll"
            checked={checkedItems.selectAll}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="selectAll">
            Select All Permission
          </CheckboxLabel>
        </div>
      </div>

      <div className="first_head_div left_marg">
        <div className="check_label">
          <CheckboxInput
            type="checkbox"
            id="child1"
            checked={checkedItems.child1}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child1">
            Transfer equipment (without the need for third-party approval)
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child2"
            checked={checkedItems.child2}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child2">
            Choice of Place (Group or Unit)
          </CheckboxLabel>
        </div>

        <div className="dual_checkbox d-flex">
          <div className="check_label left_spac">
            <CheckboxInput
              type="checkbox"
              id="child2_1"
              checked={checkedItems.child2_1}
              onChange={handleCheckboxChange}
            />
            <CheckboxLabel htmlFor="child2_1">Group</CheckboxLabel>
          </div>

          <div className="check_label left_spac">
            <CheckboxInput
              type="checkbox"
              id="child2_2"
              checked={checkedItems.child2_2}
              onChange={handleCheckboxChange}
            />
            <CheckboxLabel htmlFor="child2_2">Unit</CheckboxLabel>
          </div>
        </div>

        {(checkedItems.child2_1 || checkedItems.child1) && (
          <div className="check_label left_spac">
            <table className="group-table">
              <thead>
                <tr>
                  <th>Group Name</th>
                </tr>
              </thead>
              {groups?.map((group) => (
                <tbody key={group.id}>
                  <tr>
                    <td
                      onClick={() => {
                        handleItemClick("groups", group.id);
                      }}
                      style={{
                        cursor: "pointer",
                        fontWeight: selectedItems.some(
                          (item) =>
                            item.type === "groups" && item.id === group.id
                        )
                          ? "bold"
                          : "normal",
                      }}
                    >
                      {group.group_name}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}

        {(checkedItems.child2_2 || checkedItems.child1) && (
          <div className="check_label left_spac">
            <table className="group-table">
              <thead>
                <tr>
                  <th>Unit Name</th>
                </tr>
              </thead>
              {units?.map((unit) => (
                <tbody key={unit.id}>
                  <tr>
                    <td
                      onClick={() => handleItemClick("units", unit.id)}
                      style={{
                        cursor: "pointer",
                        fontWeight: selectedItems.some(
                          (item) => item.type === "units" && item.id === unit.id
                        )
                          ? "bold"
                          : "normal",
                      }}
                    >
                      {unit.unit_name}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child3"
            checked={checkedItems.child3}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child3">
            Selection of equipment type:
          </CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child4"
            checked={checkedItems.child4}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child4">
            Choose between contains / does not contain
          </CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child5"
            checked={checkedItems.child5}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child5">What type of equipment</CheckboxLabel>
        </div>
      </div>
      {checkedItems.child5 && (
        <div className="check_label left_spac">
          <table className="group-table">
            <thead>
              <tr>
                <th>Product Name</th>
              </tr>
            </thead>
            {products?.map((product) => (
              <tbody key={product.id}>
                <tr>
                  <td
                    onClick={() => handleItemClick("products", product.id)}
                    style={{
                      cursor: "pointer",
                      fontWeight: selectedItems.some(
                        (item) =>
                          item.type === "products" && item.id === product.id
                      )
                        ? "bold"
                        : "normal",
                    }}
                  >
                    {product.name}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
    </StyleUserForm>
  );
}

const StyleUserForm = styled.section`
  .main_heading {
    display: flex;
    justify-content: space-between;
  }

  .left_marg {
    margin-left: 20px;
    margin-top: 5px;
  }

  .left_spac {
    margin-left: 40px;
  }

  .left_hig_spac {
    margin-left: 50px;
  }

  .check_label {
    display: flex;
    gap: 10px;
  }

  .dual_checkbox {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;
