import axios from "axios";

// Base URL for API
export const API_BASE_URL = process.env.NODE_ENV === 'development' 
  // ? "http://localhost:8000/api/admin"
  ? "https://warehouse-api.phanomprofessionals.com/api/admin"
  : "https://warehouse-api.phanomprofessionals.com/api/admin";
  export const API_BASE_URL_USER = process.env.NODE_ENV === 'development' 
  // ? "http://localhost:8000/api/user"
  ? "https://warehouse-api.phanomprofessionals.com/api/user"
  : "https://warehouse-api.phanomprofessionals.com/api/user";
// Format URL with query parameters
export const UPLOAD_IMAGES =  "https://warehouse-api.phanomprofessionals.com/uploads/";
export const formatUrl = (url, params) => {
  const queryString = params
    ? `?${new URLSearchParams(params).toString()}`
    : "";
  return `${url}${queryString}`;
};   

// Generic HTTP request function for all other APIs
export const httpRequest = async (method, url, data = null, params = null, requireAuth = true) => {
  
  const axiosConfig = {
    // withCredentials: true,
    headers: requireAuth
      ? { authorization: `Bearer ${localStorage.getItem("token")}` }
      : {},     
  };

  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}${formatUrl(url, params)}`,
      data,
      ...axiosConfig 
    });
    return response;
  } catch (error) {
    return error.response; // Returning error response if it fails
  }
};
export const httpUserPostRequest = async (method, url, data = null, params = null, requireAuth = true) => {
  
  const axiosConfig = {
    // withCredentials: true,
    headers: requireAuth
      ? { authorization: `Bearer ${localStorage.getItem("token")}` }
      : {},  
  };

  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL_USER}${formatUrl(url, params)}`,
      data,
      ...axiosConfig 
    });
    return response;
  } catch (error) {
    return error.response; // Returning error response if it fails
  }
};
export const httpUserRequest = async (method, url, data = null, params = null, requireAuth = true) => {
  
  const axiosConfig = {
    // withCredentials: true,
    headers: requireAuth
      ? { authorization: `Bearer ${localStorage.getItem("token")}` }
      : {},  
  };

  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL_USER}${formatUrl(url, params)}`,
      data,
      ...axiosConfig 
    });
    return response;
  } catch (error) {
    return error.response; // Returning error response if it fails
  }
};

// Dedicated function for login API
export const loginAdmin = async (method, url, data = null, params = null, requireAuth = true) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}${formatUrl(url, params)}`,
      data,
      
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
// Dedicated function for user login API
export const userLogin = async (method, url, data = null, params = null, requireAuth = true) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL_USER}${formatUrl(url, params)}`,
      data,
      
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

// Convenience functions for specific methods
export const httpPostUser = (url, data) => httpUserPostRequest("POST", url, data);
export const httpPost = (url, data) => httpRequest("POST", url, data);
export const httpPut = (url, data) => httpRequest("PUT", url, data);
export const httpGet = (url, params) => httpRequest("GET", url, null, params);
export const httpGetUser = (url, params) => httpUserRequest("GET", url, null, params);
export const httpPostLogin = (url, data) => loginAdmin("POST", url, data);
export const httpPostUserLogin = (url, data) => userLogin("POST", url, data);

