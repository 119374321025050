import React, { useState } from "react";
import styled from "styled-components";
import { useUnitUserList } from "../../utils/useAppUtils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedUserId } from "../../redux/users/action";

// Styled Components
const Container = styled.div`
  padding: 20px;
  background-color: #f4f8fc;
  min-height: 100vh;
  font-family: Arial, sans-serif;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FilterLabel = styled.span`
  font-weight: bold;
  color: #555;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #e3f2fd;
  color: #333;
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #0056b3;
  }
`;

const Dropdown = styled.select`
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
`;

const DeleteIcon = styled.div`
  color: #ff4d4f;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    color: #ff1a1a;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  margin: 0 5px;
  background-color: ${(props) => (props.active ? "#0056b3" : "#007bff")};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

// Main Component
const Listofusers = () => {
  const memenerlsitfetch = useUnitUserList();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Calculate paginated data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = memenerlsitfetch.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(memenerlsitfetch.length / itemsPerPage);

  // Handlers for pagination
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleNavigateToQuipemtn = (id) => {
    dispatch(setSelectedUserId(id)); 
    navigate(`/userlist-equipment/${id}`); 
  };

  return (
    <Container>
      <FilterSection>
        <FilterLabel>Filter By:</FilterLabel>
        <Button>Name</Button>
      </FilterSection>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Select</TableHeader>
            <TableHeader>SL No</TableHeader>
            <TableHeader>Member Name</TableHeader>
            <TableHeader>Position</TableHeader>
            <TableHeader>Unit Name</TableHeader>
            <TableHeader>Change Role</TableHeader>
            <TableHeader>Remove</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell>
                <input type="checkbox" />
              </TableCell>
              <TableCell>{String(indexOfFirstItem + index + 1).padStart(2, "0")}</TableCell>
              <TableCell
                    onClick={() => handleNavigateToQuipemtn(item.id)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {item.name || "No Name"}
                </TableCell>              <TableCell>{item.role}</TableCell>
              <TableCell>{item.unit_name}</TableCell>
              <TableCell>
                <Dropdown>
                  <option>Soldier</option>
                  <option>Manager</option>
                  <option>Group Admin</option>
                </Dropdown>
              </TableCell>
              <TableCell>
                <DeleteIcon onClick={() => handleDelete(item.id)}>🗑️</DeleteIcon>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
        <span>Page {currentPage} of {totalPages}</span>
        <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </PaginationButton>
      </Pagination>
    </Container>
  );
};

// Define the delete function
const handleDelete = (id) => {
  console.log(`Delete user with ID: ${id}`);
};

export default Listofusers;