import {
  addUser,
  adminLogin,
  adminRegister,
  categoryList,
  createCategory,
  createGroup,
  createProduct,
  createUnit,
  createWarehouse,
  groupList,
  HIDE_LOADER,
  productPut,
  productView,
  SHOW_LOADER,
  unitList,
  userList,
  warehouseList,
  productList,
  warehouseLogin,
  userPermissionView,
  userLogin,
  userProductFetch,
  userSelectedList,
  createRoleUser,
  moveGrouptoUnit,
  deleteUnit,
  unitUserListGet,
  assignProduct,
  UserEquipmentListGetS,
  roleView,
  assignProductById,
  requestEquipmentFetch,
  reconcileProduct,
} from "../type";



export const SET_SELECTED_PRODUCT_ID = "SET_SELECTED_PRODUCT_ID";

export const setSelectedProductId = (product_id) => ({
  type: SET_SELECTED_PRODUCT_ID,
  payload: product_id,
});
// Action to show loader @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export const showLoader = () => ({ type: SHOW_LOADER });
// Action to hide loader  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export const hideLoader = () => ({ type: HIDE_LOADER });
// Action to set user details  ########################
export const setUserDetails = (data) => ({
  type: "USER_DETAILS",
  payload: data,
});
// Action for loading data  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
export const loaderAction = (data) => ({
  type: "LOADING_DATA",
  payload: data,
});
export const userDataAction = (data) => {
  return {
    type: "USER_DATA",
    payload: data,
  };
};
// Action to check user status #####################################
export const userCheckAction = (data) => ({
  type: "USER_CHECK",
  payload: data,
});
// Action for app details  #############################
export const appDetailsAction = (data) => ({
  type: "APP_DETAILS",
  payload: data,
});
// Action for warehouse login with saga *******************************************
export const warehouseLoginAction = (data, callback) => ({
  type: warehouseLogin.WAREHOUSE_LOGIN,
  data,
  callback,
});
// Action for admin login with saga *******************************************
export const adminLoginAction = (data, callback) => ({
  type: adminLogin.ADMIN_LOGIN,
  data,
  callback,
});
// Action for admin login with saga *******************************************
export const userLoginAction = (data, callback) => ({
  type: userLogin.USER_LOGIN,
  data,
  callback,
});
// Action for admin register with saga  *********************************************
export const adminRegisterAction = (data, callback) => ({
  type: adminRegister.ADMIN_REGISTER,
  data,
  callback,
});
// Action for Add Users with saga  *********************************
export const addUserAction = (data, callback) => ({
  type: addUser.ADD_USER,
  data,
  callback,
});
// Action for Create Group with saga  **************************************
export const createGroupAction = (data, callback) => ({
  type: createGroup.CREATE_GROUP,
  data,
  callback,
});
// Action for Create Group with saga  ********************************************
export const createUnitAction = (data, callback) => ({
  type: createUnit.CREATE_Unit,
  data,
  callback,
});
// Action for Create Group with saga  ********************************************
export const assignProductAction = (data, callback) => ({
  type: assignProduct.ASSIGN_PRODUCT,
  data,
  callback,
});
// Action for Create Warehouse with saga  ****************************
export const createWarehouseAction = (data, callback) => ({
  type: createWarehouse.CREATE_WAREHOUSE,
  data,
  callback,
});
// Action for create category with saga  ***************************
export const CreateCategoryAction = (data, callback) => ({
  type: createCategory.CREATE_CATEGORY,
  data,
  callback,
});
// Action for get group with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const ReconcileProductAction = (data, callback) => ({
  type: reconcileProduct.RECONCILE_PRODUCT,
  data,
  callback,
});
// Action for create product with saga  ***********************************
export const CreateProductAction = (data, callback) => ({
  type: createProduct.CREATE_PRODUCT,
  data,
  callback,
});
// Action for get unit with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getUnitAction = (data, callback) => ({
  type: unitList.UNIT_LIST,
  data,
  callback,
});

// Action for get Product List with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

export const getProductAction = (data, callback) => ({
  type: productList.PRODUCT_LIST,
  data,
  callback,
});
 

// Action for get group with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getGroupAction = (data, callback) => ({
  type: groupList.GROUP_LIST,
  data,
  callback,
});

// Action for get user with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getUserAction = (data, callback) => ({
  type: userList.USER_LIST,
  data,
  callback,
});
// Action for get user with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getUserSelectedAction = (data, callback) => ({
  type: userSelectedList.USER_SELECTED_LIST,
  data,
  callback,
});
// Action for get user with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getCategoryAction = (data, callback) => ({
  type: categoryList.CATEGORY_LIST,
  data,
  callback,
});
// Action for get user with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getWarehouseAction = (data, callback) => ({
  type: warehouseList.WAREHOUSE_LIST,
  data,
  callback,
});
// Action for get user with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getRoleAction = (data, callback) => ({
  type: roleView.ROLE_VIEW,
  data,
  callback,
});
// Action for get productView with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getProductViewAction = (data, callback, product_id) => {
  console.log(product_id, "action id"); // Log the ID for debugging

  return {
    type: productView.PRODUCT_VIEW,
    product_id,     
    data,
    callback,
  };
};
// Action for get USERPERMISSIONVIEW with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getUserPermissionViewAction = (data, callback,) => {
  return {
    type: userPermissionView.USER_PERMISSION_VIEW,   
    data,
    callback,
  };
};
// Action for get user productFetch with saga  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export const getUserProductFetchAction = (data, callback) => ({
  type: userProductFetch.USER_PRODUCT_FETCH,
  data,
  callback,
});
// Action for get productView with saga  &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
export const putProductAction = (data, callback) => ({
  type: productPut.PRODUCT_PUT,
  data,
  callback,
});
export const assignProductByIdAction = (data, callback) => ({
  type: assignProductById.ASSIGN_PRODUCT_BY_ID,
  data,
  callback,
});
export const requestEquipmentFetchAction = (data, callback) => ({
  type:requestEquipmentFetch.REQUEST_EQUIPMENT_FETCH,
  data,
  callback,
});

export const createRoleUserAction = (data, callback) => ({
  type: createRoleUser.CREATE_ROLE_USER,
  data,
  callback,
});

export const createMoveGrouptoUnitAction = (data, callback) => ({
  type: moveGrouptoUnit.MOVE_GROUPTO_UNIT,
  data,
  callback,
});
export const DeleteUnitAction = (data, callback) => ({
  type: deleteUnit.DELETE_Unit,
  data,
  callback,
});


export const UnitUserListGet = (data, callback) => ({
  type: unitUserListGet.UNIT_USER_LISTGET,
  data,
  callback,
});

// actions.js
export const SET_SELECTED_UNIT_ID = "SET_SELECTED_UNIT_ID";

export const setSelectedUnitId = (unitId) => ({
  type: SET_SELECTED_UNIT_ID,
  payload: unitId,
});


export const SET_SELECTED_USER_ID = "SET_SELECTED_USER_ID";

export const setSelectedUserId = (id) => ({
  type: SET_SELECTED_USER_ID,
  payload: id,
});

export const EquipmentUserListGet = (data, callback) => ({
  type: UserEquipmentListGetS.USER_EQUIPMENT_LISTGET,
  data,
  callback,
});
