import React, { useState } from "react";
import GlobalTable from "../Global/GlobalTable";
import { BlackBorderButton } from "../Global/GlobalButtons";
import trueTick from "../../Assets/Checkmark Button.svg";
import falseTick from "../../Assets/X Button.svg";

export const InvitedUsers = () => {
  const [data, setData] = useState([
    {
      selected: false,
      productName: "Test1",
      assignedTo: "Accepted",
      removeUser: falseTick,
      addUser: trueTick,
    },
    {
      selected: false,
      productName: "Test2",
      assignedTo: "Pending",
      removeUser: falseTick,
      addUser: trueTick,
    },
  ]);

  const columns = [
    { header: " ", accessor: "select" },
    { header: "User Name", accessor: "productName" },
    { header: "Request Sent", accessor: "assignedTo" },
    { header: "Remove User", accessor: "removeUser" },
  ];

  const handleSelectChange = (rowIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].selected = !newData[rowIndex].selected;
      return newData;
    });
  };
  return (
    <div>
      <div className="dash_table">
        <GlobalTable
          columns={columns}
          data={data.map((row, index) => ({
            ...row,
            assignedTo: (
              <BlackBorderButton
                variant="primary"
               
              >
                {row.assignedTo}
              </BlackBorderButton>
            ),
            removeUser: (
              <>
              
                <img src={row.removeUser} alt="img" style={{cursor:"pointer"}} />

                <img src={row.addUser} alt="img" style={{cursor:"pointer"}}/>
              </>
            ),
          }))}
          onSelectChange={handleSelectChange}
        />
      </div>
    </div>
  );
};
