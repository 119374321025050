import React from "react";
import styled from "styled-components";
import { IoSearchSharp } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";

const GlobalSearch = ({ search }) => {
  return (
    <Root>
      <div className="search_div">
        <div className="icon_div">
          <IoSearchSharp />
        </div>

        <div className="icon_content">
          <input type="text" placeholder={search} />
        </div>
      </div>
    </Root>
  );
};

export default GlobalSearch;

const Root = styled.section`
  .search_div {
    border: 1px solid #e5e1e1;
    background-color: #ffffff;
    height: 55px;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 20px;
    border-radius: 40px;
    .icon_div {
      width: 3%;
      svg {
        color: #718ebf;
        width: 35px;
        height: 35px;
      }
    }

    .icon_content {
      width: 97%;
      cursor: pointer;
      input[type="text"] {
        height: 50px;
        width: 100%;
        border: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
      }

      input[type="text"]::placeholder {
        color: #8ba3cb;
      }
    }
  }
`;

export const PermissionSearch = ({ search }) => {
  return (
    <PermRoot>
      <div className="search_div">
        <div className="icon_div">
          <IoSearchSharp />
        </div>

        <div className="icon_content">
          <input type="text" placeholder={search} />
        </div>
      </div>
    </PermRoot>
  );
};

const PermRoot = styled.section`
  .search_div {
    border: 1px solid #e5e1e1;
    background-color: #EFEFEF;
    height: 35px;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 20px;
    border-radius: 40px;
    .icon_div {
      width: 3%;
      svg {
        color: #718ebf;
        width: 25px;
        height: 25px;
      }
    }

    .icon_content {
      width: 97%;
      cursor: pointer;
      input[type="text"] {
        width: 100%;
        border: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
        background-color: #EFEFEF;
      }

      input[type="text"]::placeholder {
        color: #8ba3cb;
      }
    }
  }
`;

export const GlobalSearchMini = ({ searching }) => {
  return (
    <RootMini>
      <div className="search_div">
        <div className="icon_div">
          <CiSearch />
        </div>

        <div className="icon_content">
          <input type="text" placeholder={searching} />
        </div>
        <div></div>
      </div>
    </RootMini>
  );
};
const RootMini = styled.section`
  .search_div {
    border: 1px transparent;
    background-color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px 10px;
    border-radius: 40px;
    .icon_div {
      width: 3%;
      svg {
        color: #0000007a;
        width: 20px;
        height: 20px;
      }
    }

    .icon_content {
      width: 97%;
      cursor: pointer;
      input[type="text"] {
        height: 20px;
        width: 100%;
        border: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
      }

      input[type="text"]::placeholder {
        color: #0000007a;
        font-size: 14px;
      }
    }
  }
`;
