import React, { useState } from "react";
import { TabButton } from "../Global/GlobalButtons";
import styled from "styled-components";
import { InviteUserTab } from "./InviteUserTab";
import { AddUserTab } from "./AddUserTab";
 

const AddUsers = () => {
  const [selectedTab, setSelectedTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <AddUser>
      <div className="tab_div">
        
        <TabButton
          className={selectedTab === "tab1" ? "selected" : ""}
          onClick={() => handleTabClick("tab1")}
        >
          Add Users
        </TabButton>
        <TabButton
          className={selectedTab === "tab2" ? "selected" : ""}
          onClick={() => handleTabClick("tab2")}
        >
          Invite Users
        </TabButton>
      </div>

      <div className="table">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {selectedTab === "tab1" ? (
                <AddUserTab />
              ) : selectedTab === "tab2" ? (
                <InviteUserTab />
              ) : (
                <AddUserTab />
              )}
            </div>
          </div>
        </div>
      </div>
    </AddUser>
  );
};

export default AddUsers;
const AddUser = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  .table {
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
`;
