import React, { useEffect } from "react";
import styled from "styled-components";
import { CheckboxInput, CheckboxLabel } from "../../Global/GlobalPlaceholder";
import {
  useProductList,
  useUserList,
} from "../../../utils/useAppUtils";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addSelection, removeSelection } from "./action";


export default function Dummy2({checkedItems,setCheckedItems}) {
  const users = useUserList();
  const products = useProductList();

  const dispatch = useDispatch();
  
  const selectedItems = useSelector(
    (state) => state.selection.selectedItems || []
  );
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (id === "thirdPartyParentCheckbox") {
      setCheckedItems((prev) => ({
        ...prev,
        child6: checked,
        child7: checked,
        child8: checked,
        child9: checked,
        child10: checked,
        child11: checked,
        child12: checked,
        child13: checked,
        child14: checked,
         
       
      }));
    }

    // Handle third party selection
    if (id === "child6") {
      if (checkedItems.child6 && !checkedItems.child7 && !checkedItems.child8) {
        toast.error("Please select either 'Allow multiple users as third parties' or 'You can choose whether you need approval from all third parties / just one'.");
      }
    }

    // Handle approval type selection
    if (id === "child8") {
      if (checked && !checkedItems.child9) {
        toast.error("Please select either 'One Time' or 'Every time' below.");
      }
    }

    // Set the checked state
    setCheckedItems((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleItemClick = (itemType, itemId) => {
    if (!selectedItems) return; // Guard clause to prevent errors

    const isSelected = selectedItems.some(
      (item) => item.type === itemType && item.id === itemId
    );

    const payload = { type: itemType, id: itemId };

    if (isSelected) {
      dispatch(removeSelection(payload));
    } else {
      dispatch(addSelection(payload));
    }
  };

  // Log whenever selectedItems changes
  useEffect(() => {
    const logSelectedNames = () => {
      const selectedUsers = selectedItems
        .filter((item) => item.type === "users")
        .map((item) => users.find((user) => user.id === item.id)?.name);
  
        const selectedProducts = selectedItems
        .filter((item) => item.type === "products")
        .map((item) => products.find((product) => product.id === item.id)?.name);
  
      console.log("Selected users:", selectedUsers,selectedProducts);
    };
    logSelectedNames();
  }, [selectedItems,products,users]);



  return (
    <StyleUserForm>
      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="thirdPartyParentCheckbox"
              checked={checkedItems.thirdPartyParentCheckbox || false}
              onChange={handleCheckboxChange}
            />
            <CheckboxLabel htmlFor="thirdPartyParentCheckbox">
              Transfer equipment (with the need for third-party approval)
            </CheckboxLabel>
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child6"
            checked={checkedItems.child6 || false}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child6">Who is the third party</CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child7"
            checked={checkedItems.child7 || false}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child7">
            Allow multiple users as third parties
          </CheckboxLabel>
        </div>
        {(checkedItems.child7 || checkedItems.child6) && (
          <div className="check_label left_spac">
            <table className="group-table">
              <thead>
                <tr>
                  <th>Users Name</th>
                </tr>
              </thead>
              {users?.map((user) => (
                <tbody key={user.id}>
                  <tr>
                    <td
                      onClick={() => handleItemClick("users", user.id)}
                      style={{
                        cursor: "pointer",
                        fontWeight: selectedItems.some(
                          (item) => item.type === "users" && item.id === user.id
                        )
                          ? "bold"
                          : "normal",
                      }}
                    >
                      {user.name}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}


        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child8"
            checked={checkedItems.child8 || false}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child8">
            You can choose whether you need approval from all third parties / just one
          </CheckboxLabel>
        </div>

        <div className="dual_checkbox">
          <div className="check_label left_spac">
            <CheckboxInput
              type="checkbox"
              id="child9"
              checked={checkedItems.child9 || false}
              onChange={handleCheckboxChange}
            />
            <CheckboxLabel htmlFor="child9">One Time</CheckboxLabel>
          </div>

          <div className="check_label left_spac">
            <CheckboxInput
              type="checkbox"
              id="child10"
              checked={checkedItems.child10 || false}
              onChange={handleCheckboxChange}
            />
            <CheckboxLabel htmlFor="child10">Every time</CheckboxLabel>
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child11"
            checked={checkedItems.child11 || false}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child11">To whom can transfer</CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child12"
            checked={checkedItems.child12 || false}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child12">
            Selection of equipment type
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child13"
            checked={checkedItems.child13 || false}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child13" style={{ color: "#FF0000" }}>
            Choose between contains / does not contain
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child14"
            checked={checkedItems.child14 || false}
            onChange={handleCheckboxChange}
          />
          <CheckboxLabel htmlFor="child14">
            What type of equipment
          </CheckboxLabel>

          {checkedItems.child14 && (
        <div className="check_label left_spac">
          <table className="group-table">
            <thead>
              <tr>
                <th>Product Name</th>
              </tr>
            </thead>
            {products?.map((product) => (
              <tbody key={product.id}>
                <tr>
                  <td
                    onClick={() => handleItemClick("products", product.id)}
                    style={{
                      cursor: "pointer",
                      fontWeight: selectedItems.some(
                        (item) =>
                          item.type === "products" && item.id === product.id
                      )
                        ? "bold"
                        : "normal",
                    }}
                  >
                    {product.name}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
        </div>
      </div>

     
    </StyleUserForm>
  );
}

const StyleUserForm = styled.section`
  .main_heading {
    display: flex;
    justify-content: space-between;
  }

  .left_marg {
    margin-left: 20px;
    margin-top: 5px;
  }

  .left_spac {
    margin-left: 40px;
  }

  .left_hig_spac {
    margin-left: 50px;
  }

  .check_label {
    display: flex;
    gap: 10px;
  }

  .dual_checkbox {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

   
`;
