import { all, takeLatest, call, select } from "redux-saga/effects";
import {
  addUser,
  adminLogin,
  adminRegister,
  assignProduct,
  assignProductById,
  categoryList,
  createCategory,
  createGroup,
  createProduct,
  createRoleUser,
  createUnit,
  createWarehouse,
  deleteUnit,
  groupList,
  moveGrouptoUnit,
  productList,
  productPut,
  productView,
  reconcileProduct,
  requestEquipmentFetch,
  roleView,
  unitList,
  unitUserListGet,
  UserEquipmentListGetS,
  userList,
  userLogin,
  userPermissionView,
  userProductFetch,
  userSelectedList,
  warehouseList,
  warehouseLogin,
} from "./type";
import {
  httpGet,
  httpGetUser,
  httpPost,
  httpPostLogin,
  httpPostUser,
  httpPostUserLogin,
  httpPut,
} from "../utils/https";
import { toast } from "react-toastify";

// Handle API response and show toasts based on status
const handleApiResponse = (response, callback) => {
  if (response.status === 200) {
    if (callback) {
      callback(response);
    }
    toast.success(response.message);
  } else if (response.status === 400) {
    toast.error(response.message);
  } else {
    toast.error("Unexpected error occurred.");
  }
};
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST API  %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%//
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//

// -----------------------Warehouse login-------------------------- //
function* warehouseLoginSaga(action) {
  try {
    const response = yield call(httpPostLogin, "/login", action.data);
    if (response?.status === 200) {
      localStorage.setItem("token", response.data.data.token);
      yield call(handleApiResponse, response, action.callback);
      toast.success("Login successful!"); // Notify success
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    console.error("Login error:", error);
    toast.error(error.message);
  }
}
// -----------------------admin login-------------------------- //
function* adminLoginSaga(action) {
  try {
    const response = yield call(httpPostLogin, "/admin-login", action.data);
    if (response?.status === 200) {
      // Remove this line
      // localStorage.setItem("token", token);
      
      // Keep only this line
      localStorage.setItem("token", response.data.data.token);

      yield call(handleApiResponse, response, action.callback);
      toast.success("Login successful!"); // Notify success
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    console.error("Login error:", error); // Log the error for debugging
    toast.error(error.message);
  }
}

// -----------------------user login-------------------------- //
function* userLoginSaga(action) {
  try {
    console.log("Attempting login with:", action.data); // Log the action data
    const response = yield call(httpPostUserLogin, "/user-login", action.data);

    console.log("API response in saga:", response); // Log the API response for debugging

    if (response?.status === 200 ) {

      localStorage.setItem("token", response.data.data.token);
      yield call(handleApiResponse, response, action.callback);
    } else {
      console.error(response?.message); // Log failure status
      toast.error(response?.data?.message);
      // <a href="/userlogin"></a>
    }
  } catch (error) {
    console.error("Login error:", error); // Log the error for debugging
    toast.error(error.message);
  }
}

// -----------------------admin register-------------------------- //
function* adminRegisterSaga(action) {
  try {
    const response = yield call(httpPostLogin, "/admin-register", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during register.");
  }
}
// ----------------------------------Add Users Api--------------------------- //
function* addUsersPostApi(action) {
  try {
    const response = yield call(httpPost, "/user-create", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// -------------------create group api--------------------- //
function* createGroupPostApi(action) {
  try {
    const response = yield call(httpPost, "/group-create", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// -------------------create unit api--------------------- //
function* createUnitPostApi(action) {
  try {
    const response = yield call(httpPost, "/unit-create", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// -------------------create warehuse api--------------------- //
function* createWarehousePostApi(action) {
  try {
    const response = yield call(httpPost, "/warehouse-create", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------create category api in saga------------------------------- //
function* createCategoryPostApi(action) {
  try {
    const response = yield call(httpPost, "/add-category", action.data);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------create product api in saga------------------------------- //
function* createProductPostApi(action) {
  try {
    const response = yield call(httpPost, "/product-create", action.data);
    console.log("kyuuu", response);
    if (response.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    } else if (response.status === 400) {
      toast.warn("Product Name already exists");
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET API  %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%//
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//

// ----------------------------group list api in saga------------------------------- //
function* groupListGetApi(action) {
  try {
    const response = yield call(httpGet, "/get-grouplist", action.data);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------unit list api in saga------------------------------- //
function* unitListGetApi(action) {
  try {
    const response = yield call(httpGetUser, "/userUnit-fetch", action.data);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------user list api in saga------------------------------- //
function* userListGetApi(action) {
  try {
    const response = yield call(httpGet, "/get-userlist", action.data);

    localStorage.getItem("token");

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------fetch user list api in saga------------------------------- //
function* userSelectedListGetApi(action) {
  try {
    const response = yield call(httpGetUser, "/userfetchlist", action.data);
    localStorage.getItem("token");
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------user list api in saga------------------------------- //
function* categoryGetApi(action) {
  try {
    const response = yield call(httpGet, "/get-categorylist", action.data);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------user list api in saga------------------------------- //
function* warehouseGetApi(action) {
  try {
    const response = yield call(httpGet, "/get-warehouselist", action.data);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------Product list api in saga------------------------------- //
function* productListGetApi(action) {
  try {
    const response = yield call(httpGet, "/productlist", action.data);
    localStorage.getItem("token");
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
 // --------------------------------user product fetch View api in saga------------------------------- //

function* userProductFetchGetApi(action) {
  // Proceed with API call for non-admin users
  try {
    const response = yield call(httpGetUser, `/userProduct-fetch`, action.data);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      console.log("response in arsa why",response)
    }
  } catch (error) {
    toast.error("Error occurred during fetching permissions.");
  }
}

// --------------------------------product View api in saga------------------------------- //

function* productViewGetApi(action) {
  try {
    const selectedProductId = yield select(
      (state) => state?.users?.selectedProductId
    );
    console.log("iddxxxd", selectedProductId);

    const response = yield call(
      httpGet,
      `/get-prodcuct/${selectedProductId}`,
      action.data
    );
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------role View api in saga------------------------------- //

function* roleViewGetApi(action) {
  try {
    const response = yield call(
      httpGetUser,
      `/rolefetchall`,
      action.data
    );
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
// --------------------------------permission View api in saga------------------------------- //

export function* userPermissionViewGetApi(action) {
  const userDetails = yield select((state) => state?.users?.user);
  // Check if the token exists
  if (!userDetails) {
    console.log("No Permission - No Token Found");
    return; 
  }

  // Skip API call if the user is an admin
  if (userDetails.role === "admin") {
    console.log("Admin has all permissions, skipping API call");
    return;
  }
  // Proceed with API call for non-admin users
  try {
    const response = yield call(httpGetUser, `/permission-fetch`, action.data);
    console.log("Response:", response);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    console.error("Error occurred during fetching permissions:", error);
    toast.error("Error occurred during fetching permissions.");
  }
}


//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT API  %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%//
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//

// --------------------------------product View api in saga------------------------------- //
function* productPutApi(action) {
  const selectedProductId = yield select(
    (state) => state?.users?.selectedProductId
  );
  console.log("putidddddd", selectedProductId);
  try {
    const response = yield call(
      httpPut,
      `/product-update/${selectedProductId}`,
      action.data
    );
    console.log("list", response);

    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}

function* createUserRolePostApi(action) {
  try {
    const response = yield call(httpPost, "/create-userole", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}

function* MoveGroupToUnit(action) {
  try {
    const response = yield call(httpPost, "/movegroup-unit", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}

function* DeleteUnit(action) {
  try {
    const response = yield call(httpPost, "/unit-delete", action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}


function* UnitUserListGet(action) {
  const selectedUnitId =  yield select(
    (state) => state?.users?.selectedUnitId);
  try {
    const response = yield call(httpGetUser, `/group-userlist/${selectedUnitId}`, action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } 
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}

// function* ReconcileProductPost(action) {
//   try {
//     const { productIds, assignedTo } = action.data;
//     const response = yield call(httpPostUser, `/reconcile-product`, { productIds, assignedTo });
//     if (response?.status === 200) {
//       yield call(handleApiResponse, response, action.callback);
//       toast.success(response.data.message);
//     } 
//   } catch (error) {
//     toast.error("Error occurred during Creation.");           
//   }
// }
function* AssignProductPost(action) {
  try {
    const { productIds, assignedTo } = action.data;
    const response = yield call(httpPostUser, `/assignProduct`, { productIds, assignedTo });
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } 
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}

// function* assignProductByIdApi(action) {
//   try {
//     const selectedProductId = yield select(
//       (state) => state?.users?.selectedProductId
//     );
//     console.log("assignProductByIdApi", selectedProductId);

//     const response = yield call(
//       httpGetUser,
//       `/productassign/${selectedProductId}`,
//       action.data
//     );
//     if (response?.status === 200) {
//       yield call(handleApiResponse, response, action.callback);
//     }
//   } catch (error) {
//     toast.error("Error occurred during Creation.");
//   }
// }

function* UserEquipmentListGet(action) {
  const selectedUserId = yield select(
    (state) => state?.users?.selectedUserId
  );
  try {
    const response = yield call(
      httpGetUser,
      `/userEquipment?user_id=${selectedUserId}`,
      action.data
    );
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } 
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
function* requestEquipmentFetchGet(action) {
  try {
    const response = yield call(httpGetUser, `/requestEquipmentFetch`, action.data);
    if (response?.status === 200) {
      yield call(handleApiResponse, response, action.callback);
      toast.success(response.data.message);
    } 
  } catch (error) {
    toast.error("Error occurred during Creation.");
  }
}
function* watchLoginUser() {
  yield takeLatest(adminLogin.ADMIN_LOGIN, adminLoginSaga);
  yield takeLatest(userLogin.USER_LOGIN, userLoginSaga);
  yield takeLatest(warehouseLogin.WAREHOUSE_LOGIN, warehouseLoginSaga);
  yield takeLatest(adminRegister.ADMIN_REGISTER, adminRegisterSaga);
  yield takeLatest(addUser.ADD_USER, addUsersPostApi);
  yield takeLatest(assignProduct.ASSIGN_PRODUCT, AssignProductPost);
  yield takeLatest(createGroup.CREATE_GROUP, createGroupPostApi);
  yield takeLatest(createUnit.CREATE_Unit, createUnitPostApi);
  yield takeLatest(createWarehouse.CREATE_WAREHOUSE, createWarehousePostApi);
  yield takeLatest(createCategory.CREATE_CATEGORY, createCategoryPostApi);
  yield takeLatest(createProduct.CREATE_PRODUCT, createProductPostApi);
  yield takeLatest(groupList.GROUP_LIST, groupListGetApi);
  yield takeLatest(unitList.UNIT_LIST, unitListGetApi);
  yield takeLatest(productList.PRODUCT_LIST, productListGetApi);
  // yield takeLatest(reconcileProduct.RECONCILE_PRODUCT, ReconcileProductPost);
  yield takeLatest(userList.USER_LIST, userListGetApi);
  yield takeLatest(userSelectedList.USER_SELECTED_LIST, userSelectedListGetApi);
  yield takeLatest(categoryList.CATEGORY_LIST, categoryGetApi);
  yield takeLatest(warehouseList.WAREHOUSE_LIST, warehouseGetApi);
  yield takeLatest(productView.PRODUCT_VIEW, productViewGetApi);
  yield takeLatest(roleView.ROLE_VIEW, roleViewGetApi);
  yield takeLatest(requestEquipmentFetch.REQUEST_EQUIPMENT_FETCH, requestEquipmentFetchGet);
  yield takeLatest(userProductFetch.USER_PRODUCT_FETCH, userProductFetchGetApi);
  yield takeLatest(UserEquipmentListGetS.USER_EQUIPMENT_LISTGET, UserEquipmentListGet);
  yield takeLatest(
    userPermissionView.USER_PERMISSION_VIEW,
    userPermissionViewGetApi
  );
  yield takeLatest(productPut.PRODUCT_PUT, productPutApi);
  yield takeLatest(createRoleUser.CREATE_ROLE_USER, createUserRolePostApi);
  yield takeLatest(moveGrouptoUnit.MOVE_GROUPTO_UNIT, MoveGroupToUnit);
  yield takeLatest(deleteUnit.DELETE_Unit, DeleteUnit);
  yield takeLatest(unitUserListGet.UNIT_USER_LISTGET, UnitUserListGet);

}

export default function* commonSaga() {
  yield all([watchLoginUser()]);
}
