import React, { useState } from "react";
import styled from "styled-components";
import { BlueBorderButton, BlueBorderContent, GrayBorderButton, TabButton } from "../Global/GlobalButtons";
import GlobalSearch from "../Global/GlobalSearch";
import GlobalTable from "../Global/GlobalTable";

export const Request = () => {
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [selectedButton, setSelectedButton] = useState(1);
  const [data, setData] = useState([
    {
      slNo: 1,
      datetime: "June 20, 2024",
      image: "CapIcon",
      productname: "Army Cap",
      productid: "Cap1",
      category: "Cap",
      profile: "Manager",
      requestedforunit: <BlueBorderButton>Name user</BlueBorderButton>,
      requestedforgroup: <BlueBorderButton>Group name</BlueBorderButton>,
      status:<GrayBorderButton>Accepted</GrayBorderButton>,
    },
  ]);

  const [dataTwo, setDataTwo] = useState([
    {
      slNo: 1,
      datetime: "June 20, 2024",
      image: "CapIcon",
      teamstrength: "200",
      position: "Manager",
      requestedbyunit: <BlueBorderButton>Name user</BlueBorderButton>,
      requestedforgroup: <BlueBorderButton>Group name</BlueBorderButton>,
      status: <GrayBorderButton>Accepted</GrayBorderButton>,
 
    },
  ]);
  

  const columns = [
    { header: "Select", accessor:"select"},
    { header: "SL No", accessor:"slNo"},
    { header: "Date & Time", accessor: "datetime"},
    { header: "Image", accessor: "image"},
    { header: "Product Name", accessor: "productname"},
    { header: "Product ID", accessor: "productid"},
    { header: "Category", accessor: "category"},
    { header: "Profile", accessor: "profile"},
    { header: "Requested For / Unit", accessor:"requestedforunit"},
    { header: "Requested For / Group", accessor:"requestedforgroup"},
    { header: "Status", accessor: "status"},
  ];


  const columnsTwo = [
    { header: "Select", accessor:"select"},
    { header: "SL No", accessor:"slNo"},
    { header: "Date & Time", accessor: "datetime"},
    { header: "Equipment List", accessor: "image"},
    { header: "Team Strength", accessor: "teamstrength"},
    { header: "Position", accessor: "position"},
    { header: "Requested By / Unit", accessor: "requestedbyunit"},
    { header: "Requested For/ Group", accessor: "requestedforgroup"},
    { header: "Status", accessor:"status"},
  
  ];

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const onSelectChange = (index) => {
    if (selectedTab === "tab1" || selectedTab === "tab2") {
      const newData = [...data];
      newData[index].selected = !newData[index].selected;
      setData(newData);
    } else if (selectedTab === "tab3" || selectedTab === "tab4") {
      const newDataTwo = [...dataTwo];
      newDataTwo[index].selected = !newDataTwo[index].selected;
      setDataTwo(newDataTwo);
    }
  };



  // Function to render the button group
  const renderButtonGroup = () => (
    <div className="button_div">
      <BlueBorderContent
        className={selectedButton === 1 ? "selected" : ""}
        onClick={() => handleButtonClick(1)}
      >
        Product name
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 2 ? "selected" : ""}
        onClick={() => handleButtonClick(2)}
      >
        Category
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 3 ? "selected" : ""}
        onClick={() => handleButtonClick(3)}
      >
        Profile
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 4 ? "selected" : ""}
        onClick={() => handleButtonClick(4)}
      >
        Requested By
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 5 ? "selected" : ""}
        onClick={() => handleButtonClick(5)}
      >
        Group/Unit
      </BlueBorderContent>
    </div>
  );

  // Function to render the added button group
  const renderAddedButtonGroup = () => (
    <div className="button_div">
      <BlueBorderContent
        className={selectedButton === 6 ? "selected" : ""}
        onClick={() => handleButtonClick(6)}
      >
        Position
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 7 ? "selected" : ""}
        onClick={() => handleButtonClick(7)}
      >
        Equipment List
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 8 ? "selected" : ""}
        onClick={() => handleButtonClick(8)}
      >
        Date & Time
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 9 ? "selected" : ""}
        onClick={() => handleButtonClick(9)}
      >
        Requested By
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 10 ? "selected" : ""}
        onClick={() => handleButtonClick(10)}
      >
        Group/Unit
      </BlueBorderContent>
    </div>
  );

  return (
    <Root>
      <div className="tab_div">
        <TabButton
          className={selectedTab === "tab1" ? "selected" : ""}
          onClick={() => handleTabClick("tab1")}
        >
          Member Request Sent
        </TabButton>

        <TabButton
          className={selectedTab === "tab2" ? "selected" : ""}
          onClick={() => handleTabClick("tab2")}
        >
          Member Requested Received
        </TabButton>

        <TabButton
          className={selectedTab === "tab3" ? "selected" : ""}
          onClick={() => handleTabClick("tab3")}
        >
          Group Requested Received
        </TabButton>

        <TabButton
          className={selectedTab === "tab4" ? "selected" : ""}
          onClick={() => handleTabClick("tab4")}
        >
          Group Requested Sent
        </TabButton>
      </div>

      <div className="search_main_div">
        <GlobalSearch search="Search for something"/>
      </div>

      <div className="table">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

              {selectedTab === "tab1" || selectedTab === "tab2" ? (
                <>
                  {renderButtonGroup()}
                  <div className="table_div">
                    <GlobalTable
                      columns={columns}
                      data={data}
                      onSelectChange={onSelectChange}
                    />
                  </div>
                </>
              ) : selectedTab === "tab3" || selectedTab === "tab4" ? (
                <>
                {renderAddedButtonGroup()}
                <div className="table_div">
                    <GlobalTable
                      columns={columnsTwo}
                      data={dataTwo}
                      onSelectChange={onSelectChange}
                    />
                  </div>
                
                </>
              ) : (
                <h1>Create Group</h1>
              )}

            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;

  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .button_div {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .search_main_div {
    padding: 20px 50px;
  }

  .table_div{
    margin-top:30px;
    .table {
    width: 100%;
    height: 100%;
    margin-top:20px;
  }
  }
 
`;
