import React, { useState } from "react";
import styled from "styled-components";
import GlobalTable from "../Global/GlobalTable";
import { BlackBorderButton } from "../Global/GlobalButtons";

const InvitedList = () => {
  const [data, setData] = useState([
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Manager",
      request: "accepted",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Soldier",
      request: "Pending",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Soldier",
      request: "accepted",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Area In Charge",
      request: "Pending",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Sub Manager",
      request: "accepted",
    },
  ]);

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "User Name", accessor: "userName" },
    { header: "Group Name", accessor: "groupName" },
    { header: "Unit Name", accessor: "unitName" },
    { header: "Request Sent ", accessor: "request" },
  ];

  // const handleSelectChange = (rowIndex) => {
  //   setData((prevData) => {
  //     const newData = [...prevData];
  //     newData[rowIndex].selected = !newData[rowIndex].selected;
  //     return newData;
  //   });
  // };

  return (
    <StyleInvitedList>
      <div className="dash_table">
      <GlobalTable
          columns={columns}
          data={data.map((row, index) => ({
            ...row,
            request: (
              <BlackBorderButton
                onClick={() => alert(`Request is ${row.request}`)}
              >
                {row.request === "accepted" ? "Accepted" : "Pending"}
              </BlackBorderButton>
            ),
          }))}
        />
      </div>
    </StyleInvitedList>
  );
};

export default InvitedList;
const StyleInvitedList = styled.section``;
