import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  assignProductByIdAction,
  EquipmentUserListGet,
  getCategoryAction,
  getGroupAction,
  getProductAction,
  getProductViewAction,
  getRoleAction,
  getUnitAction,
  getUserAction,
  getUserPermissionViewAction,
  getUserProductFetchAction,
  getUserSelectedAction,
  getWarehouseAction,
  requestEquipmentFetchAction,
  UnitUserListGet,
} from "../redux/users/action";

// Custom hook that returns dispatch and navigate
const useAppUtils = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return { dispatch, navigate };
};

export default useAppUtils;

export const useUnitList = () => {
  const [units, setUnits] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUnitList = () => {
      dispatch(
        getUnitAction(units, (response) => {
          if (response?.status === 200) {
            setUnits(response?.data?.data);
          }
        })
      );
    };

    fetchUnitList();
  }, [dispatch, units, getUnitAction]);

  return units;
};

export const useProductList = () => {
  const [productList, setproductList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductList = () => {
      dispatch(
        getProductAction(productList, (response) => {
          if (response?.status === 200) {
            setproductList(response?.data?.data);
          }
        })
      );
    };

    fetchProductList();
  }, [dispatch, productList]);

  return productList;
};

export const useGroupList = () => {
  const [groups, setGroups] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGroupList = () => {
      dispatch(
        getGroupAction(groups, (response) => {
          if (response?.status === 200) {
            setGroups(response?.data?.data);
          }
        })
      );
    };

    fetchGroupList();
  }, [dispatch, groups, getGroupAction]);

  return groups;
};

export const useUserList = () => {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserList = () => {
      dispatch(
        getUserAction(users, (response) => {
          if (response?.status === 200) {
            setUsers(response?.data?.data);
          }
        })
      );
    };

    fetchUserList();
  }, [dispatch]);

  return users;
};

export const useUserSelectedList = () => {
  const [usersSelect, setUsersSelect] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserSelectedList = () => {
      dispatch(
        getUserSelectedAction(usersSelect, (response) => {
          if (response?.status === 200) {
            setUsersSelect(response?.data?.data);
          }
        })
      );
    };

    fetchUserSelectedList();
  }, [dispatch, usersSelect]);

  return usersSelect;
};

export const useCategoryList = () => {
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCategoryList = () => {
      dispatch(
        getCategoryAction(categories, (response) => {
          if (response?.status === 200) {
            setCategories(response?.data?.data);
          }
        })
      );
    };

    fetchCategoryList();
  }, [categories, dispatch]);

  return categories;
};

export const useWarehouseList = () => {
  const [warehouse, setWarehouse] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchWarehouseList = () => {
      dispatch(
        getWarehouseAction(warehouse, (response) => {
          if (response?.status === 200) {
            setWarehouse(response?.data?.data);
          }
        })
      );
    };

    fetchWarehouseList();
  }, [dispatch, warehouse]);

  return warehouse;
};

export const useRoleList = () => {
  const [role, setRole] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRoleList = () => {
      dispatch(
        getRoleAction(role, (response) => {
          if (response?.status === 200) {
            setRole(response?.data?.data);
          }
        })
      );
    };

    fetchRoleList();
  }, [dispatch, role]);

  return role;
};

export const useProductView = () => {
  const [product, setProduct] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductView = () => {
      dispatch(
        getProductViewAction(product, (response) => {
          if (response?.status === 200) {
            setProduct(response?.data?.data);
          }
        })
      );
    };

    fetchProductView();
  }, [dispatch]);

  return product;
};

export const useUserProductFetch = () => {
  const [userProductFetch, setUserProductFetch] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const userFetchProductView = () => {
      dispatch(
        getUserProductFetchAction(userProductFetch, (response) => {
          if (response?.status === 200) {
            setUserProductFetch(response?.data?.data);
          }
        })
      );
    };

    userFetchProductView();
  }, [dispatch, userProductFetch]);

  return userProductFetch;
};

export const usePermission = () => {
  const [permission, setPermission] = useState([]);
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPermission = () => {
      dispatch(
        getUserPermissionViewAction(permission, (response) => {
          if (response?.status === 200 && Array.isArray(response?.data?.data)) {
            setPermission(response.data.data);
          } else {
            setPermission([]);
          }
          // setLoading(false);

          console.log("Fetched Permissions:", response?.data?.data);
        })
      );
    };
    fetchPermission();
  }, [permission, dispatch]);

  return { permission };
};

export const useUnitUserList = () => {
  const [unitss, setUnitss] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUnitListusers = () => {
      dispatch(
        UnitUserListGet(unitss, (response) => {
          if (response?.status === 200) {
            setUnitss(response?.data?.data);
          }
        })
      );
    };

    fetchUnitListusers();
  }, [dispatch]);

  return unitss;
};

export const useUserEquipmentList = () => {
  const [equipmentid, setequipmentid] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchEquipmentListusers = () => {
      dispatch(
        EquipmentUserListGet(equipmentid, (response) => {
          if (response?.status === 200) {
            setequipmentid(response?.data?.data);
          }
        })
      );
    };

    fetchEquipmentListusers();
  }, [dispatch]);

  return equipmentid;
};

export const useRequestEquipment = () => {
  const [requestEquipment, setRequestEquipment] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequestEquipment = () => {
      dispatch(
        requestEquipmentFetchAction(requestEquipment, (response) => {
          if (response?.status === 200) {
            setRequestEquipment(response?.data?.data);
          }
        })
      );
    };

    fetchRequestEquipment();
  }, [dispatch]);

  return requestEquipment;
};
