import React from "react";
import GlobalUserProfile from "../Global/GlobalUserProfile";
import GlobalUserForm from "../Global/GlobalUserForm";

export const AddSubWarehouse = () => {
  return (
    <div>
      <div>
      <GlobalUserProfile
          WarehouseName="Warehouse Name"
          XYZwareHouse="XYZ WareHouse"
          Descriptionaboutwarehouse="Description about warehouse"
          Writedescriptionhere="Write description here"
          MemberfromUnitName="Member from Unit Name"
          Admincanassignunitfromhere="Admin can assign unit from here"
          AssignTo="Assign To"
          CharleneReed="Charlene Reed"
          MemberFromGroupName="MemberFromGroupName"
          Admincanassigngroupfromhere="Admin can assign groupfromhere"
          Role="Role"
          TypeRolehere="Type Role here"
        />
      </div>
      <div>
        <GlobalUserForm />
      </div>
    </div>
  );
};
