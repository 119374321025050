import React, { useState } from "react";
import Dummy from "./Dummy";
import Dummy2 from "./Dummy2";
// import { BlueBackgroundButton } from "../../Global/GlobalButtons";
// import Dummy3 from "./Dummy3";
// import Dummy4 from "./Dummy4";
// import Dummy5 from "./Dummy5";

export default function DummyFolder() {
  const [checkedItems, setCheckedItems] = useState({
    selectAll: false,
    child1: false,
    child2: false,
    child2_1: false,
    child2_2: false,
    child3: false,
    child4: false,
    child5: false,
    thirdPartyParentCheckbox:false,
    child6: false,
    child7: false,
    child8: false,
    child9: false,
    child10: false,
    child11: false,
    child12: false,
    child13: false,
    child14: false,
    noLimitCheckbox:false,
    requestEquipmentParentCheckbox:false,
    child15: false,
    child16: false,
    child17: false,
    child18: false,
    child19: false,
   
  });
  return (
    <div>
      <Dummy checkedItems={checkedItems} setCheckedItems={setCheckedItems}/>
      <Dummy2 checkedItems={checkedItems}  setCheckedItems={setCheckedItems}/>
      {/* <Dummy3  checkedItems={checkedItems}  setCheckedItems={setCheckedItems}/>
      <Dummy4  checkedItems={checkedItems}  setCheckedItems={setCheckedItems} />
      <Dummy5  checkedItems={checkedItems}  setCheckedItems={setCheckedItems} /> */}
      
    </div>
  );
}
