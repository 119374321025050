export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const LOGOUT_USER = "LOGOUT_USER";
export const adminLogin = {
    ADMIN_LOGIN: 'ADMIN_LOGIN',
};
export const userLogin = {
    USER_LOGIN: 'USER_LOGIN',
};
export const warehouseLogin = {
   WAREHOUSE_LOGIN: 'WAREHOUSE_LOGIN',
};
export const requestEquipmentFetch = {
   REQUEST_EQUIPMENT_FETCH: 'REQUEST_EQUIPMENT_FETCH',
};
export const adminRegister = {
    ADMIN_REGISTER: 'ADMIN_REGISTER',
};
export const addUser = {
   ADD_USER: 'ADD_USER',
};
export const createGroup = {
   CREATE_GROUP: 'CREATE_GROUP',
};
export const createUnit = {
   CREATE_Unit: 'CREATE_Unit',
};
export const createWarehouse = {
   CREATE_WAREHOUSE: 'CREATE_WAREHOUSE',
};
export const createCategory = {
   CREATE_CATEGORY: 'CREATE_CATEGORY',
};
export const reconcileProduct = {
   RECONCILE_PRODUCT: 'RECONCILE_PRODUCT',
};
export const createProduct = {
   CREATE_PRODUCT: 'CREATE_PRODUCT',
};
export const assignProduct = {
   ASSIGN_PRODUCT: 'ASSIGN_PRODUCT',
};
export const UserEquipmentListGetS = {
   USER_EQUIPMENT_LISTGET: 'USER_EQUIPMENT_LISTGET',
};
export const unitList = {
    UNIT_LIST: 'UNIT_LIST',
};
export const roleView = {
   ROLE_VIEW: 'ROLE_VIEW',
};
export const assignProductById = {
   ASSIGN_PRODUCT_BY_ID: 'ASSIGN_PRODUCT_BY_ID',
};

// ------

export const createRoleUser = {
   CREATE_ROLE_USER: 'CREATE_ROLE_USER',
};

export const moveGrouptoUnit = {
   MOVE_GROUPTO_UNIT: 'MOVE_GROUPTO_UNIT',
};

export const deleteUnit = {
   DELETE_Unit: 'DELETE_Unit',
};


export const unitUserListGet = {
   UNIT_USER_LISTGET: 'UNIT_USER_LISTGET',
};
export const productList= {
  PRODUCT_LIST: 'PRODUCT_LIST',
};
export const groupList = {
   GROUP_LIST: 'GROUP_LIST',
};
export const userList = {
   USER_LIST: 'USER_LIST',
};
export const categoryList = {
   CATEGORY_LIST: 'CATEGORY_LIST',
};
export const warehouseList = {
   WAREHOUSE_LIST: 'WAREHOUSE_LIST',
};
export const productView = {
   PRODUCT_VIEW: 'PRODUCT_VIEW',
};
export const userPermissionView = {
   USER_PERMISSION_VIEW: 'USER_PERMISSION_VIEW',
};
export const productPut = {
   PRODUCT_PUT: 'PRODUCT_PUT',
};
export const userProductFetch = {
   USER_PRODUCT_FETCH: 'USER_PRODUCT_FETCH',
};
export const userSelectedList = {
   USER_SELECTED_LIST: 'USER_SELECTED_LIST',
};
