    import { combineReducers } from "redux";
    import UserReducer from "../users/UserReducer";
    import { selectionReducer } from "../../components/users/DummyFolder/reducer";
    

    const rootReducer = combineReducers({
        users: UserReducer,
        selection: selectionReducer,

    })

    export default rootReducer;