// components/GlobalTable.js
import React from "react";
import styled from "styled-components";

const GlobalTable = ({ columns, data, onSelectChange, onRowClick }) => {
 
  return (
    <RootTable>
      <table>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column.accessor === "select" ? (
                    <input
                      type="checkbox"
                      checked={row.selected}
                      onChange={() => onSelectChange(rowIndex)}
                    />
                  ) : column.accessor === "name" ? ( // Make only "username" cell clickable
                    <span
                      onClick={() => onRowClick(row)}
                      style={{ cursor: "pointer" }}
                    >
                      {row[column.accessor]}
                    </span>
                  ) : column.accessor === "image" ? (
                    <img
                      src={require(`../../Assets/${row[column.accessor]}.png`)}
                      alt={row[column.accessor]}
                      style={{ width: "50px", height: "50px" }}
                    />
                  ) : column.render ? (
                    column.render(rowIndex)
                  ) : (
                    row[column.accessor]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </RootTable>
  );
};

export default GlobalTable;

export const RootTable = styled.section`
  background-color: #ffff;
  border-radius: 20px;

  table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
  }
  th {
    color: #718ebf;
  }
  td {
    color: #232323;
  }

  tr {
    border-bottom: 1px solid #e6eff5;
  }

  th,
  td {
    text-align: left;
    padding: 10px 5px;
    text-align: center;
    font-size: 13px;
  }

  img {
    width: 50px;
    height: 35px;
    object-fit: contain;
  }
`;
