import styled from "styled-components";

export const BorderInput = styled.input`
  height: 40px;
  border-radius: 12px;
  border: 1px solid #ffffff;
  opacity: 0px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 38.73px;
  color: #000000;
  padding: 0 10px;
  background-color: #f0f0f0;

  ::placeholder {
    font-size: 18px;
    font-weight: 400;
    line-height: 38.73px;
    color: #000000;
  }

`;

export const BorderDiv = styled.div`
  height: 40px;
  border-radius: 12px;
  border: 1px solid transparent;
  opacity: 0px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 38.73px;
  color: #000000;
  padding: 0 10px;
  background-color: #f0f0f0;
 
`;
export const BorderSelect = styled.select`
  height: 40px;
  border-radius: 12px;
  border: 1px solid #dfeaf2;
  opacity: 0px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  color: #4b4949;
  padding: 0 10px;
  background-color: #f0f0f0;
  outline: none;
  ::placeholder {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    color: #4b4949;
  }
`;
export const DottedSelect = styled.select`
  height: 30px;
  border-radius: 15px;
  border: 1px dotted #000;
  opacity: 0px;
  margin: 0px 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  color: #4b4949;
  padding: 0 10px;
  background-color: #f0f0f0;
  ::placeholder {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    color: #4b4949;
  }
`;

export const BorderTextarea = styled.textarea`
  border-radius: 25px;
  border: 1px solid #f0f0f0;
  font-size: 20px;
  font-weight: 400;
  line-height: 29.05px;
  color: #4b4949;
  min-height: 300px;
  opacity: 1; /* Ensure the textarea is visible */
  padding: 20px;
  background-color: #f0f0f0;
  width: 100%; /* Set width to 100% or any specific width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's width */
  resize: none; /* Prevent resizing */

  ::placeholder {
    font-size: 20px;
    font-weight: 400;
    line-height: 29.05px;
    color: #4b4949;
    font-family: "Inter";
  }
`;

export const CheckboxInput = styled.input`
font-size: 20px;
font-weight: 500;
line-height: 24.2px;
color:#c9c9c9;
`;

export const CheckboxLabel = styled.label`
font-size: 16px;
font-weight: 500;
line-height: 24.2px;
color:#000000;



`;
