import React, { useState } from "react";
import styled from "styled-components";
import { GrEdit } from "react-icons/gr";
import userImage from "../../Assets/ProfileIcon.png";
import { BorderInput, BorderSelect } from "./GlobalPlaceholder";
import { Textlabel } from "./GlobalText";
import { Table2 } from "./Table2";
import GlobalSearch from "./GlobalSearch";
import { Modal } from "react-bootstrap";

export default function GlobalUserProfile({
  WarehouseName,
  XYZwareHouse,
  Descriptionaboutwarehouse,
  Writedescriptionhere,
  MemberfromUnitName,
  Admincanassignunitfromhere,
  AssignTo,
  CharleneReed,
  MemberFromGroupName,
  Admincanassigngroupfromhere,
  Role,
  TypeRolehere,
}) {
  const [showReconciliation, setShowReconciliation] = useState(false);
  const handleClose = () => {
    setShowReconciliation(false);
  };
  const handleShowReconciliation = () => setShowReconciliation(true);
  return (
    <StyleProfile>
      <div className="profile_edit">
        <img src={userImage} alt="profile edit" />
        <button className="edit_icon">
          <GrEdit />
        </button>
      </div>
      <div className="input_select">
        <div className="input_select_name">
          <Textlabel htmlFor="name">{WarehouseName}</Textlabel>
          <BorderInput placeholder={XYZwareHouse} />
        </div>
        <div className="input_select_desc">
          <Textlabel htmlFor="description">{Descriptionaboutwarehouse}</Textlabel>
          <BorderInput placeholder={Writedescriptionhere} />
        </div>
        <div className="input_select_unit">
          <Textlabel htmlFor="unit name">{MemberfromUnitName}</Textlabel>
          <BorderSelect>
            <option>{Admincanassignunitfromhere}</option>
          </BorderSelect>
        </div>
      </div>
      <div className="input_select">
        <div className="input_select_assign">
          <Textlabel htmlFor="name">{AssignTo}</Textlabel>
          <BorderSelect onClick={handleShowReconciliation}>
            <option onClick={handleShowReconciliation}>{CharleneReed}</option>
          </BorderSelect>
        </div>
        <div className="input_select_group">
          <Textlabel htmlFor="group name">{MemberFromGroupName}</Textlabel>
          <BorderInput placeholder={Admincanassigngroupfromhere} />
        </div>
        <div className="input_select_sub_div">
          <Textlabel htmlFor="role">{Role}</Textlabel>
        
          <BorderSelect  >
            <option >{TypeRolehere} </option>
          </BorderSelect>
        </div>
      </div>
      <Modal show={showReconciliation} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <div>
            <GlobalSearch search="Search by Name,Id,Group,Unit" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table2 request="Request Now" />
        </Modal.Body>
      </Modal>
    </StyleProfile>
  );
}
const StyleProfile = styled.section`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 0px 40px;
  flex-wrap: wrap;
  .profile_edit {
    display: flex;
    position: relative;
    align-items: baseline;
    img {
      width: 120px;
      height: 120px;
    }
    .edit_icon {
      background-color: transparent;
      border: none;
    }
    svg {
      cursor: pointer;
      background-color: #1814f3;
      color: #fff;
      width: 35px;
      height: 35px;
      padding: 7px;
      position: absolute;
      bottom: 0;
      left: 72px;
      border-radius: 50%;
    }
  }
  .input_select {
    display: flex;
    flex-direction: column;
    .input_select_sub_div,
    .input_select_name,
    .input_select_desc,
    .input_select_unit,
    .input_select_assign,
    .input_select_group {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      label {
        padding: 0px 10px 10px;
        font-size: 14px;
      }
      input,
      select {
        border: 1.5px solid #dfeaf2;
        background-color: #fff;
      }
      input::placeholder,
      select::placeholder {
        font-size: 13px !important;
        font-weight: 400;
        line-height: 16px;
        color: #4b4949;
      }
    }
  }
`;
