import React, { useState } from "react";
import styled from "styled-components";
import {
  BlackBorderButton,
  BlueBackgroundButton,
  TagButton,
} from "../Global/GlobalButtons";
import {
  FormTextBlack,
  HistoryContent,
  HistorySubContent,
  Textlabel,
  UploadContent,
} from "../Global/GlobalText";
import {
  BorderInput,
  BorderSelect,
  BorderTextarea,
} from "../Global/GlobalPlaceholder";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import imagelogo from "../../Assets/PhotoIcon.png";
import circleimage from "../../Assets/circleimage.png";
import note from "../../Assets/note.png";
import { Modal } from "react-bootstrap";
import GlobalSearch from "../Global/GlobalSearch";
import { Table2 } from "../Global/Table2";
import editSrc from "../../Assets/note.png";
import noimg from "../../Assets/noImg.png";
import { RxCross2 } from "react-icons/rx";
import { BorderDiv } from "../Global/GlobalPlaceholder";
import * as Yup from "yup";
import useAppUtils, {
  useCategoryList,
  useWarehouseList,
} from "../../utils/useAppUtils";
import { CreateProductAction } from "../../redux/users/action";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(500, "Description cannot exceed 500 characters"),
  categoryid: Yup.string().required("Category must be Selected"),
  warehouseid: Yup.string().required("Warehouse must be Selected"),
  army_cap: Yup.number().required("Number is required"),
  sku: Yup.number()
    .typeError("SKU must be a number")
    .required("SKU is required"),
  comments: Yup.string()
    .required("Comment is required")
    .max(50, "Comments cannot exceed 50 characters"),
  image: Yup.mixed()
    .required("Image is required")
    .test(
      "fileFormat",
      "Only image files (JPEG, PNG,JPG, GIF) are accepted",
      (value) => {
        return (
          value &&
          ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
            value.type
          )
        );
      }
    )
    .test(
      "fileSize",
      "File size must be less than or equal to 1 MB",
      (value) => value && value.size <= 1048576
    ),
});
export const AddProduct = () => {
  const [showInput, setShowInput] = useState(false);
  const [showReconciliation, setShowReconciliation] = useState(false);
  const [showAssignProduct, setShowAssignProduct] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const { dispatch, navigate } = useAppUtils();
  const categories = useCategoryList();
  const warehouse = useWarehouseList();

  const initialValues = {
    name: "",
    description: "",
    image: null,
    sku: "",
    categoryid: "",
    warehouseid: "",
    army_cap: "",
    comments: "",
  };

  const handleClose = () => {
    setShowReconciliation(false);
    setShowAssignProduct(false);
  };
  const handleShowReconciliation = () => setShowReconciliation(true);
  const handleShowAssignProduct = () => setShowAssignProduct(true);

  const handleTagClick = (tag) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(tag)) {
        // Remove tag if already selected
        return prevTags.filter((t) => t !== tag);
      } else {
        // Add tag if not already selected
        return [...prevTags, tag];
      }
    });
  };

  const handleTagRemove = (tag) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t !== tag));
  };

  const handleImageClick = () => {
    setShowInput((prevShowInput) => !prevShowInput);
  };

  const handleCreateProduct = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    // Append form values to the FormData object
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("categoryid", values.categoryid);
    formData.append("warehouseid", values.warehouseid);
    formData.append("army_cap", values.army_cap);
    formData.append("comments", values.comments);
    formData.append("sku", values.sku);
    formData.append("image", values.image);
    dispatch(
      CreateProductAction(formData, (response) => {
        setSubmitting(false);
        if (response.status === 200) {
          resetForm();
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
  return (
    <ProductRoot>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleCreateProduct}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <div className="save_btn">
              <BlueBackgroundButton type="submit" disabled={isSubmitting}>
                Save
              </BlueBackgroundButton>
            </div>

            <div className="product_main_div">
              <div className="armycap_div">
                <div className="cap_div">
                  <div className="icon_cap_head">
                    <HiOutlineArrowNarrowLeft />
                    <FormTextBlack>Product Name</FormTextBlack>
                  </div>

                  <Field
                    as={BorderInput}
                    placeholder="Product Name"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                  />
                  
                  <ErrorMessage name="name" component="div" className="error"/>

                  <div className="category_div">
                    <FormTextBlack>Category Description</FormTextBlack>
                    <Field
                      as={BorderTextarea}
                      maxLength="500"
                      placeholder="Enter the description (Max 500 characters)"
                      name="description"
                      type="text"
                      value={values.description}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="upload_image">
                    <FormTextBlack>Upload Image</FormTextBlack>
                    <div className="upload_div">
                      <div className="upload_btn">
                        <BlackBorderButton>Upload New</BlackBorderButton>
                        <input
                          type="file"
                          accept="image/*"
                          className="file_input"
                          onChange={(event) => {
                            setFieldValue("image", event.target.files[0]);
                          }}
                        />
                      </div>
                      <UploadContent>
                        {values.image
                          ? values.image.name
                          : "Add Your Images Here"}
                      </UploadContent>
                    </div>
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>
              <div className="four_btn_div">
                <BlueBackgroundButton>Move Product</BlueBackgroundButton>
                <BlueBackgroundButton>Request a Product</BlueBackgroundButton>
                <BlueBackgroundButton onClick={handleShowAssignProduct}>
                  Assign Product
                </BlueBackgroundButton>
                <BlueBackgroundButton onClick={handleShowReconciliation}>
                  Product Reconciliation
                </BlueBackgroundButton>
              </div>
            </div>

            <div className="category_main_div">
              <div className="cat_sub_div">
                <FormTextBlack>Category</FormTextBlack>
                <Field name="categoryid" as={BorderSelect}>
                  <option value="">Select Group</option>
                  {categories?.map((cat) => {
                    console.log("Rendering cat:", cat);
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="categoryid"
                  component="div"
                  className="error"
                />
              </div>

              <div className="cat_sub_div">
                <FormTextBlack>Army Cap</FormTextBlack>
                <Field
                  as={BorderInput}
                  placeholder="Product Number"
                  name="army_cap"
                  type="number"
                  value={values.army_cap}
                  onChange={(e) => setFieldValue("army_cap", e.target.value)}
                />
                <ErrorMessage
                  name="army_cap"
                  component="div"
                  className="error"
                />
              </div>

              <div className="cat_sub_div">
                <FormTextBlack>SKU(Automatic)</FormTextBlack>
                <Field
                  as={BorderInput}
                  placeholder="SKU"
                  name="sku"
                  type="number"
                  value={values.sku}
                  onChange={(e) => setFieldValue("sku", e.target.value)}
                />
                <ErrorMessage name="sku" component="div" className="error" />
              </div>

              <div className="cat_sub_div">
                <FormTextBlack>Warehouse</FormTextBlack>
                <Field name="warehouseid" as={BorderSelect}>
                  <option value="">Select Group</option>
                  {warehouse?.map((ware) => {
                    console.log("Rendering cat:", ware);
                    return (
                      <option key={ware.id} value={ware.id}>
                        {ware.username}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="warehouseid"
                  component="div"
                  className="error"
                />
              </div>
            </div>

            <div className="label_div">
              <FormTextBlack>Label Color (Optional)</FormTextBlack>

              {/* Show selected tags outside the input field */}
              <BorderDiv>
                {selectedTags.map((tag) => (
                  <TagButton key={tag}>
                    {tag} <RxCross2 onClick={() => handleTagRemove(tag)} />
                  </TagButton>
                ))}
              </BorderDiv>

              {/* Tag buttons for selection */}

              <div className="tag_btn_div">
                <TagButton onClick={() => handleTagClick("Tag1")}>
                  Tag1
                </TagButton>
                <TagButton onClick={() => handleTagClick("Tag2")}>
                  Tag2
                </TagButton>
                <TagButton onClick={() => handleTagClick("Tag3")}>
                  Tag3
                </TagButton>
                <TagButton onClick={() => handleTagClick("Tag4")}>
                  Tag4
                </TagButton>
                <TagButton onClick={() => handleTagClick("Tag5")}>
                  Tag5
                </TagButton>
              </div>
            </div>

            <div className="component_div">
              <FormTextBlack>Components (Optional)</FormTextBlack>
              <div className="cont_div">
                <div className="cont_lab_div">
                  <Textlabel htmlFor="image">Image</Textlabel>
                  <div className="upload_btn">
                    <img src={imagelogo} alt="imagelogo" />
                    <input type="file" className="file_input" multiple />
                  </div>
                </div>
                <div className="cont_lab_div">
                  <Textlabel htmlFor="name">Name</Textlabel>
                  <BorderInput></BorderInput>
                </div>
                <div className="cont_lab_div">
                  <Textlabel htmlFor="quantity">Quantity</Textlabel>
                  <BorderInput></BorderInput>
                </div>
              </div>
            </div>

            <div className="comments_div">
              <FormTextBlack>Comments</FormTextBlack>

              <Field
                as={BorderTextarea}
                maxLength="50"
                placeholder="Enter comments (Max 50 characters)"
                name="comments"
                type="text"
                value={values.comments}
                onChange={(e) => setFieldValue("comments", e.target.value)}
              />
              <ErrorMessage name="comments" component="div" className="error" />
            </div>

            <div className="product_history">
              <FormTextBlack>Product History</FormTextBlack>
              <div className="history_div">
                <div className="line_div">
                  <img src={circleimage} alt="circleimage" />
                </div>
                <div className="cont_div">
                  <img src={imagelogo} alt="imagelogo" />
                  <div className="img_cont_div">
                    <HistoryContent>Cap Assign to ABC Soldier</HistoryContent>
                    <HistorySubContent>
                      Assign By ABC (warehouse no 8)
                    </HistorySubContent>
                  </div>
                </div>

                <div className="date_time_div">
                  <HistoryContent>12-8-24</HistoryContent>
                  <HistoryContent>10:00 AM</HistoryContent>
                </div>

                <div className="note_div">
                  <img src={note} alt="note_img" onClick={handleImageClick} />
                  {showInput && <BorderInput />}
                </div>
              </div>
            </div>

            <div className="save_btn">
              <BlueBackgroundButton>Save</BlueBackgroundButton>
            </div>
          </Form>
        )}
      </Formik>
      <StyledModal
        show={showAssignProduct}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <div className="search_div">
            <GlobalSearch search="Search by Name,Id,Group,Unit" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table2 request="Assign Now" edit={editSrc} />
        </Modal.Body>
      </StyledModal>

      <StyledModal
        show={showReconciliation}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <div className="search_div">
            <GlobalSearch search="Search by Name,Id,Group,Unit" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table2 request="Request Now" edit={noimg} />
        </Modal.Body>
      </StyledModal>
    </ProductRoot>
  );
};

export const ProductRoot = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px;

  .save_btn {
    display: flex;
    justify-content: flex-end;
  }

  .product_main_div {
    display: flex;
    gap: 30px;
    .armycap_div {
      width: 60%;

      .cap_div {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 567px) {
          width: 100%;
        }

        .icon_cap_head {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        .category_div {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
      .upload_image {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (max-width: 567px) {
          width: 100%;
        }

        .upload_div {
          background-color: #f0f0f0;
          padding: 30px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          border-radius: 25px;
          .upload_btn {
            display: flex;
            justify-content: center;
            position: relative;
            .file_input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
    .four_btn_div {
      width: 40%;

      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  }

  .category_main_div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
    .cat_sub_div {
      flex: 1;
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
  }

  .label_div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    margin-top: 20px;
    .tag_btn_div {
      display: flex;
      gap: 20px;
      .tag_btn {
      }
    }
  }

  .component_div {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 50%;
    margin-top: 20px;
    .cont_div {
      display: flex;
      gap: 20px;
      .cont_lab_div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .upload_btn {
          display: flex;
          position: relative;
          img {
            width: 50px;
          }
          .file_input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  .comments_div {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 60%;
    margin-top: 20px;
  }

  .product_history {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 60%;
    margin-top: 20px;
    .history_div {
      display: flex;
      gap: 10px;
      .line_div {
        width: 5%;

        img {
          width: 50px;
          height: 40px;
          object-fit: cover;
        }
      }
      .cont_div {
        width: 37%;

        display: flex;

        img {
          width: 50px;
          height: 40px;
        }
        .img_cont_div {
          display: flex;
          flex-direction: column;
        }
      }
      .date_time_div {
        width: 25%;

        display: flex;
        gap: 20px;
      }

      .note_div {
        width: 30%;
        display: flex;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
      }
    }
  }

  @media (max-width: 567px) {
    .product_main_div {
      flex-direction: column-reverse;
      .armycap_div {
        width: 100%;
      }
    }
    .label_div {
      width: 100%;
    }
    .label_div .tag_btn_div {
      flex-wrap: wrap;
    }
    .component_div {
      width: 100%;

      .cont_div {
        flex-wrap: wrap;
      }
    }

    .comments_div {
      width: 100%;
    }
    .product_history {
      width: 100%;
      .history_div {
        overflow: auto;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .search_div {
    width: 80%;
  }
`;
