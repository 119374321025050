import React, { useState } from "react";
import styled from "styled-components";
import { AssignNowButton, BlackBorderButton } from "./GlobalButtons";
import profilelogo from "../../Assets/Mask Group (10).png";

const Container = styled.div`
  padding: 20px;
  background-color: #f0f8ff;
`;

const RoleSelector = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #eaf4fc;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #f0f8ff;
  font-weight: bold;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #ffffff;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

export const Table2 = ({request,edit}) => {
  const [selectedRole, setSelectedRole] = useState("Manager");

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const data = [
    {
      name: "Yadidi",
      profile: "Manager",
      id: "1234567",
      group: "ABC",
      unit: "XYZ",
      image: profilelogo,
    },
   
  ];

  return (
    <Container>
      <RoleSelector>
        {" "}
        <b>Select</b>
        {["Soldier", "Manager", "Commander", "Chief"].map((role) => (
          <BlackBorderButton
            key={role}
            className={selectedRole === role ? "active" : ""}
            onClick={() => handleRoleSelect(role)}
          >
            {role}
          </BlackBorderButton>
        ))}
      </RoleSelector>

      <TableWrapper>
        <Table>
          <tbody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <input type="checkbox"/>
                </TableCell>
                <TableCell>
                  <TableHeader> </TableHeader>
                  <ProfileImage src={item.image} alt={item.name} />
                </TableCell>
                <TableCell>
                  {" "}
                  <TableHeader>Name</TableHeader>
                  {item.name}
                </TableCell>
                <TableCell>
                  <TableHeader>Profile</TableHeader>
                  {item.profile}
                </TableCell>
                <TableCell>
                  <TableHeader>ID</TableHeader>
                  {item.id}
                </TableCell>
                <TableCell>
                  <TableHeader>Group</TableHeader>
                  {item.group}
                </TableCell>
                <TableCell>
                  {" "}
                  <TableHeader>Unit</TableHeader>
                  {item.unit}
                </TableCell>
                <TableCell>
                  {" "}
                  <TableHeader>Action</TableHeader>
                  <AssignNowButton>{request}</AssignNowButton>
                  
                </TableCell>
                <TableCell>
                  {" "}
                  <TableHeader></TableHeader>
                  
                  <img src={edit} alt="edit" />
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </Container>
  );
};


export const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <PaginationContainer>
      <PaginationButton disabled={currentPage === 1} onClick={() => onPageChange(Math.max(1, currentPage - 1))}>
        Previous
      </PaginationButton>

      {[...Array(totalPages)].map((_, index) => (
        <PaginationButton
          key={index}
          className={currentPage === index + 1 ? 'active' : ''}
          onClick={() => onPageChange(index + 1)}
        >
          {index + 1}
        </PaginationButton>
      ))}

      <PaginationButton disabled={currentPage === totalPages} onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}>
        Next
      </PaginationButton>
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; // Adjust the gap between buttons as needed
`;

const PaginationButton = styled.button`
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  &.active {
    background-color: #007bff;
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;