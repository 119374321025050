import React, { useEffect, useState } from "react";
import styled from "styled-components";
import notification from "../Assets/NotificationIcon.png";
import profile from "../Assets/ProfileIcon.png";
import Logstix from "../Assets/Logstix.png";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons/lib";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import useAppUtils, {usePermission } from "../utils/useAppUtils";
import { BlueBackgroundButton } from "../components/Global/GlobalButtons";
import { FiLogOut } from "react-icons/fi";
import {userCheckAction, userDataAction } from "../redux/users/action";

export default function Layout({ children }) {
  const { dispatch, navigate } = useAppUtils();
  const [activeMenu, setActiveMenu] = useState(null);
  const userCheck = useSelector((state) => state?.users?.userCheck);
  const userDetails = useSelector((state) => state?.users?.user);

  const token = localStorage.getItem("token");
  const { permission: permissionsArray } = usePermission();

  const permissionIds = permissionsArray.map(
    (permission) => permission.permission_id
  );
  useEffect(() => {}, [permissionsArray]);

  // Filter sidebar data based on permissions

  const getFilteredSidebarData = () => {
    if (userDetails.role === "admin") {
      return SidebarData(); // Admins see all sidebar items
    }
    return SidebarData().filter((item) => {
      const isItemAllowed = permissionIds.includes(item.id);
      if (item.subNav) {
        item.subNav = item.subNav.filter((subItem) =>
          permissionIds.includes(subItem.id)
        );
        return isItemAllowed || item.subNav.length > 0;
      }
      return isItemAllowed;
    });
  };

  const filteredSidebarData = getFilteredSidebarData();

  const handleLogout = () => {
    localStorage.removeItem("token"); 
    dispatch(userCheckAction(false)); 
    dispatch(userDataAction(""));
    navigate("/userlogin");
  };
  
  return (
    <Root>
      <Nav>
        <div style={{ display: "flex", alignItems: "center" }}>
          <NavIcon to="#" style={{ flexDirection: "column" }}>
            <img src={Logstix} alt="Logstix Logo" />
          </NavIcon>
          <H1Tag>
            Logstix
            <span> SIMPLE ENGAGING ORGANIZED </span>
          </H1Tag>
        </div>
        {userCheck && token ? (
          <div className="notification-profile">
            <p>{userDetails?.username}</p>
            <img
              className="notification"
              src={notification}
              alt="notification"
            />
            <img className="profile" src={profile} alt="profile" />
          </div>
        ) : (
          !token && (
            <>
              <div onClick={() => navigate("/adminlogin")}>
                <BlueBackgroundButton>Login</BlueBackgroundButton>
              </div>
              <div onClick={() => navigate("/userlogin")}>
                <BlueBackgroundButton>User Login</BlueBackgroundButton>
              </div>
              <div onClick={() => navigate("/login")}>
                <BlueBackgroundButton>Warehouse Login</BlueBackgroundButton>
              </div>
            </>
          )
        )}
      </Nav>
      <div className="main_body">
        {userCheck && token && (
          <div className="sideBar">
            <IconContext.Provider value={{ color: "#fff" }}>
              <SidebarNav className="sidebar_society">
                <SidebarWrap>
                  {filteredSidebarData.map((item, index) => (
                    <React.Fragment key={index}>
                      <SidebarLink
                        to={item.path}
                        onClick={() => {
                         if (item.subNav) {
                            setActiveMenu((prevActiveMenu) =>
                              prevActiveMenu === item.path ? null : item.path
                            );
                          }
                        }}
                        activeclassname="selected"
                      >
                        <div className="icon_content">
                          {item.icon}
                          <SidebarLabel className="side_label">
                            {item.title}
                          </SidebarLabel>
                        </div>
                        <div className="open_close_icon">
                          {item.subNav && activeMenu === item.path
                            ? item.iconOpened
                            : item.subNav
                            ? item.iconClosed
                            : null}
                        </div>
                      </SidebarLink>

                      {item.subNav &&
                        activeMenu === item.path &&
                        item.subNav.map((subItem, subIndex) => (
                          <DropdownLink
                            to={subItem.path}
                            key={subIndex}
                            activeclassname="selected"
                            className="sub_menu"
                          >
                            {subItem.icon}
                            <SidebarLabel>{subItem.title}</SidebarLabel>
                          </DropdownLink>
                        ))}
                    </React.Fragment>
                  ))}
                  <React.Fragment>
                    <SidebarLink
                      onClick={() => {
                        handleLogout();
                      
                      }}
                    >
                      <div className="icon_content">
                        <FiLogOut />
                        <SidebarLabel className="side_label">
                          Logout
                        </SidebarLabel>
                      </div>
                    </SidebarLink>
                  </React.Fragment>
                </SidebarWrap>
              </SidebarNav>
            </IconContext.Provider>
          </div>
        )}
        <div className="page">{children}</div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .main_body {
    display: flex;
    flex: 1;
    min-height: 80vh;
    height: 100%;
    min-width: 100vw;
    width: 100%;
    background-color: #e5f4fb;
    overflow: hidden;
    margin-top: 6%;

    @media (max-width: 567px) {
      margin-top: 20%;
    }

    @media (min-width: 567px) and (max-width: 992px) {
      margin-top: 8%;
    }

    .sideBar {
      width: 25%;
      position: static;
      @media (max-width: 567px) {
        &:hover {
          width: 60%;
        }
      }
      @media (min-width: 567px) and (max-width: 992px) {
        width: 45%;
      }
    }

    .sidebar_society {
      @media (max-width: 567px) {
        &:hover {
          .side_label {
            display: block;
          }
        }

        &:hover {
          .sub_menu {
            display: block;
            padding: 5px 5px 30px 35px;
          }
        }

        &:hover {
          .open_close_icon svg {
            display: block;
          }
        }
      }
    }

    .page {
      border-radius: 20px;
      height: 100%;
      padding: 20px 30px;
      width: 100%;
      @media (max-width: 567px) {
        padding: 20px 10px;
        width: 85%;
      }
    }
  }

  @media (max-width: 567px) {
    .side_label {
      display: none;
    }

    .icon_content {
      display: flex;
    }

    .sub_menu {
      display: none;
    }

    .open_close_icon svg {
      display: block;
    }
  }
`;

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #000;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3px;
  list-style: none;
  height: 20px;
  text-decoration: none;
  font-size: 16px;
  border: 6px solid #fff;

  svg {
    color: inherit;
  }

  &:hover {
    color: #2d60ff;
    border-left: 5px solid #2d60ff;
    cursor: pointer;
  }

  &.selected,
  &.active {
    color: #2d60ff;
    background-color: #fff;
    border-left: 6px solid #2d60ff;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 15px;
  font-size: 15px;
`;

const DropdownLink = styled(NavLink)`
  background: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  border: 4px solid #fff;
  background-color: lightgray;
  border-radius: 20px;
  padding: 1rem 0 1rem 3rem;

  &:hover {
    color: #2d60ff;
    border: 4px solid #fff;
    cursor: pointer;
    svg {
      color: #2d60ff;
    }
  }

  &.selected,
  &.active {
    color: #fff;
    background-color: #2d60ff;
    border-radius: 20px;
    border: 4px solid #fff;
    svg {
      color: #fff;
    }
  }
`;

const Nav = styled.div`
  background: #fff;
  color: #343c6a;
  height: 80px;
  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
  padding: 10px 20px;
  box-shadow: 1px 1px 4px 1px #f2f2f2;
  position: fixed;
  z-index: 1111;
  .icon_phone {
    display: none;
  }
  .notification-profile {
    display: flex;
    gap: 20px;
    align-items: center;
    p{
      margin: 0;
      text-transform: capitalize;
      font-weight: 500 ;
    }
    .notification {
      height: 40px;
      width: 40px;
    }
    .profile {
      height: 60px;
      width: 60px;
    }
  }
  @media (max-width: 876px) {
    padding: 0px 20px;
    .icon_phone {
      display: block;
    }
  }
`;

const SidebarNav = styled.nav`
  background: #fff;
  width: 20%;
  position: fixed !important;
  color: #000;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  left: ${({ sidebar, isMobile }) => (isMobile && !sidebar ? "-100%" : "0")};
  transition: 350ms;
  z-index: 10;
  margin-top: 6%;

  @media (max-width: 567px) {
    margin-top: 20%;
    width: 15%;
    &:hover {
      width: 60%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    width: 30%;
    margin-top: 8%;
  }
`;

const SidebarWrap = styled.div`
  overflow-y: auto;
  padding: 20px 0px;
  width: 100%;
  box-shadow: 1px 1px 4px 1px #f2f2f2;
  svg {
    color: #000 !important;
    width: 20px;
    height: 20px;
  }
`;

const NavIcon = styled(Link)`
  display: none;
  margin: 0px;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    color: #000 !important;
    height: auto;
    width: 20px;
  }
  img {
    height: 65px;
    width: auto;
  }
  @media (max-width: 876px) {
    display: flex;
    img {
      height: 46px;
      width: auto;
    }
  }
`;

const H1Tag = styled.h1`
  text-align: center;
  margin-left: 20px;
  color: #343c6a;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-family: Inter;
  font-size: 26px;
  line-height: 33px;

  span {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  @media (max-width: 876px) {
    font-size: 20px;
    margin: 0;
    span {
      font-size: 8px;
      line-height: 14px;
    }
  }
`;
